import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import moment from 'moment';

const ChartLastThirty = ({ dayTotals }) => {
  // needed to reference the canvas element when the chart is created
  const chartRef = useRef(null);
  const today = new Date();
  const day1 = moment(today).format('l');
  const day2 = moment(today)
    .subtract(1, 'days')
    .format('l');
  const day3 = moment(today)
    .subtract(2, 'days')
    .format('l');
  const day4 = moment(today)
    .subtract(3, 'days')
    .format('l');
  const day5 = moment(today)
    .subtract(4, 'days')
    .format('l');
  const day6 = moment(today)
    .subtract(5, 'days')
    .format('l');
  const day7 = moment(today)
    .subtract(6, 'days')
    .format('l');
  const day8 = moment(today)
    .subtract(7, 'days')
    .format('l');
  const day9 = moment(today)
    .subtract(8, 'days')
    .format('l');
  const day10 = moment(today)
    .subtract(9, 'days')
    .format('l');
  const day11 = moment(today)
    .subtract(10, 'days')
    .format('l');
  const day12 = moment(today)
    .subtract(11, 'days')
    .format('l');
  const day13 = moment(today)
    .subtract(12, 'days')
    .format('l');
  const day14 = moment(today)
    .subtract(13, 'days')
    .format('l');
  const day15 = moment(today)
    .subtract(14, 'days')
    .format('l');
  const day16 = moment(today)
    .subtract(15, 'days')
    .format('l');
  const day17 = moment(today)
    .subtract(16, 'days')
    .format('l');
  const day18 = moment(today)
    .subtract(17, 'days')
    .format('l');
  const day19 = moment(today)
    .subtract(18, 'days')
    .format('l');
  const day20 = moment(today)
    .subtract(19, 'days')
    .format('l');
  const day21 = moment(today)
    .subtract(20, 'days')
    .format('l');
  const day22 = moment(today)
    .subtract(21, 'days')
    .format('l');
  const day23 = moment(today)
    .subtract(22, 'days')
    .format('l');
  const day24 = moment(today)
    .subtract(23, 'days')
    .format('l');
  const day25 = moment(today)
    .subtract(24, 'days')
    .format('l');
  const day26 = moment(today)
    .subtract(25, 'days')
    .format('l');
  const day27 = moment(today)
    .subtract(26, 'days')
    .format('l');
  const day28 = moment(today)
    .subtract(27, 'days')
    .format('l');
  const day29 = moment(today)
    .subtract(28, 'days')
    .format('l');
  const day30 = moment(today)
    .subtract(29, 'days')
    .format('l');
  const dayArr = [
    day1.replace('2020', '20').replace('2019', '19'),
    day2.replace('2020', '20').replace('2019', '19'),
    day3.replace('2020', '20').replace('2019', '19'),
    day4.replace('2020', '20').replace('2019', '19'),
    day5.replace('2020', '20').replace('2019', '19'),
    day6.replace('2020', '20').replace('2019', '19'),
    day7.replace('2020', '20').replace('2019', '19'),
    day8.replace('2020', '20').replace('2019', '19'),
    day9.replace('2020', '20').replace('2019', '19'),
    day10.replace('2020', '20').replace('2019', '19'),
    day11.replace('2020', '20').replace('2019', '19'),
    day12.replace('2020', '20').replace('2019', '19'),
    day13.replace('2020', '20').replace('2019', '19'),
    day14.replace('2020', '20').replace('2019', '19'),
    day15.replace('2020', '20').replace('2019', '19'),
    day16.replace('2020', '20').replace('2019', '19'),
    day17.replace('2020', '20').replace('2019', '19'),
    day18.replace('2020', '20').replace('2019', '19'),
    day19.replace('2020', '20').replace('2019', '19'),
    day20.replace('2020', '20').replace('2019', '19'),
    day21.replace('2020', '20').replace('2019', '19'),
    day22.replace('2020', '20').replace('2019', '19'),
    day23.replace('2020', '20').replace('2019', '19'),
    day24.replace('2020', '20').replace('2019', '19'),
    day25.replace('2020', '20').replace('2019', '19'),
    day26.replace('2020', '20').replace('2019', '19'),
    day27.replace('2020', '20').replace('2019', '19'),
    day28.replace('2020', '20').replace('2019', '19'),
    day29.replace('2020', '20').replace('2019', '19'),
    day30.replace('2020', '20').replace('2019', '19'),
  ];

  useEffect(() => {
    const drawChart = () => {
      const config = {
        type: 'horizontalBar',
        data: {
          labels: dayArr,
          datasets: [
            {
              label: 'Protein',
              data: [
                dayTotals[day1] ? Math.round(dayTotals[day1].protein) : 0,
                dayTotals[day2] ? Math.round(dayTotals[day2].protein) : 0,
                dayTotals[day3] ? Math.round(dayTotals[day3].protein) : 0,
                dayTotals[day4] ? Math.round(dayTotals[day4].protein) : 0,
                dayTotals[day5] ? Math.round(dayTotals[day5].protein) : 0,
                dayTotals[day6] ? Math.round(dayTotals[day6].protein) : 0,
                dayTotals[day7] ? Math.round(dayTotals[day7].protein) : 0,
                dayTotals[day8] ? Math.round(dayTotals[day8].protein) : 0,
                dayTotals[day9] ? Math.round(dayTotals[day9].protein) : 0,
                dayTotals[day10] ? Math.round(dayTotals[day10].protein) : 0,
                dayTotals[day11] ? Math.round(dayTotals[day11].protein) : 0,
                dayTotals[day12] ? Math.round(dayTotals[day12].protein) : 0,
                dayTotals[day13] ? Math.round(dayTotals[day13].protein) : 0,
                dayTotals[day14] ? Math.round(dayTotals[day14].protein) : 0,
                dayTotals[day15] ? Math.round(dayTotals[day15].protein) : 0,
                dayTotals[day16] ? Math.round(dayTotals[day16].protein) : 0,
                dayTotals[day17] ? Math.round(dayTotals[day17].protein) : 0,
                dayTotals[day18] ? Math.round(dayTotals[day18].protein) : 0,
                dayTotals[day19] ? Math.round(dayTotals[day19].protein) : 0,
                dayTotals[day20] ? Math.round(dayTotals[day20].protein) : 0,
                dayTotals[day21] ? Math.round(dayTotals[day21].protein) : 0,
                dayTotals[day22] ? Math.round(dayTotals[day22].protein) : 0,
                dayTotals[day23] ? Math.round(dayTotals[day23].protein) : 0,
                dayTotals[day24] ? Math.round(dayTotals[day24].protein) : 0,
                dayTotals[day25] ? Math.round(dayTotals[day25].protein) : 0,
                dayTotals[day26] ? Math.round(dayTotals[day26].protein) : 0,
                dayTotals[day27] ? Math.round(dayTotals[day27].protein) : 0,
                dayTotals[day28] ? Math.round(dayTotals[day28].protein) : 0,
                dayTotals[day29] ? Math.round(dayTotals[day29].protein) : 0,
                dayTotals[day30] ? Math.round(dayTotals[day30].protein) : 0,
              ],
              backgroundColor: [
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
              ],
            },
            {
              label: 'Carbs',
              data: [
                dayTotals[day1]
                  ? Math.round(dayTotals[day1].total_carbohydrate)
                  : 0,
                dayTotals[day2]
                  ? Math.round(dayTotals[day2].total_carbohydrate)
                  : 0,
                dayTotals[day3]
                  ? Math.round(dayTotals[day3].total_carbohydrate)
                  : 0,
                dayTotals[day4]
                  ? Math.round(dayTotals[day4].total_carbohydrate)
                  : 0,
                dayTotals[day5]
                  ? Math.round(dayTotals[day5].total_carbohydrate)
                  : 0,
                dayTotals[day6]
                  ? Math.round(dayTotals[day6].total_carbohydrate)
                  : 0,
                dayTotals[day7]
                  ? Math.round(dayTotals[day7].total_carbohydrate)
                  : 0,
                dayTotals[day8]
                  ? Math.round(dayTotals[day8].total_carbohydrate)
                  : 0,
                dayTotals[day9]
                  ? Math.round(dayTotals[day9].total_carbohydrate)
                  : 0,
                dayTotals[day10]
                  ? Math.round(dayTotals[day10].total_carbohydrate)
                  : 0,
                dayTotals[day11]
                  ? Math.round(dayTotals[day11].total_carbohydrate)
                  : 0,
                dayTotals[day12]
                  ? Math.round(dayTotals[day12].total_carbohydrate)
                  : 0,
                dayTotals[day13]
                  ? Math.round(dayTotals[day13].total_carbohydrate)
                  : 0,
                dayTotals[day14]
                  ? Math.round(dayTotals[day14].total_carbohydrate)
                  : 0,
                dayTotals[day15]
                  ? Math.round(dayTotals[day15].total_carbohydrate)
                  : 0,
                dayTotals[day16]
                  ? Math.round(dayTotals[day16].total_carbohydrate)
                  : 0,
                dayTotals[day17]
                  ? Math.round(dayTotals[day17].total_carbohydrate)
                  : 0,
                dayTotals[day18]
                  ? Math.round(dayTotals[day18].total_carbohydrate)
                  : 0,
                dayTotals[day19]
                  ? Math.round(dayTotals[day19].total_carbohydrate)
                  : 0,
                dayTotals[day20]
                  ? Math.round(dayTotals[day20].total_carbohydrate)
                  : 0,
                dayTotals[day21]
                  ? Math.round(dayTotals[day21].total_carbohydrate)
                  : 0,
                dayTotals[day22]
                  ? Math.round(dayTotals[day22].total_carbohydrate)
                  : 0,
                dayTotals[day23]
                  ? Math.round(dayTotals[day23].total_carbohydrate)
                  : 0,
                dayTotals[day24]
                  ? Math.round(dayTotals[day24].total_carbohydrate)
                  : 0,
                dayTotals[day25]
                  ? Math.round(dayTotals[day25].total_carbohydrate)
                  : 0,
                dayTotals[day26]
                  ? Math.round(dayTotals[day26].total_carbohydrate)
                  : 0,
                dayTotals[day27]
                  ? Math.round(dayTotals[day27].total_carbohydrate)
                  : 0,
                dayTotals[day28]
                  ? Math.round(dayTotals[day28].total_carbohydrate)
                  : 0,
                dayTotals[day29]
                  ? Math.round(dayTotals[day29].total_carbohydrate)
                  : 0,
                dayTotals[day30]
                  ? Math.round(dayTotals[day30].total_carbohydrate)
                  : 0,
              ],
              backgroundColor: [
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
              ],
            },
            {
              label: 'Fat',
              data: [
                dayTotals[day1] ? Math.round(dayTotals[day1].total_fat) : 0,
                dayTotals[day2] ? Math.round(dayTotals[day2].total_fat) : 0,
                dayTotals[day3] ? Math.round(dayTotals[day3].total_fat) : 0,
                dayTotals[day4] ? Math.round(dayTotals[day4].total_fat) : 0,
                dayTotals[day5] ? Math.round(dayTotals[day5].total_fat) : 0,
                dayTotals[day6] ? Math.round(dayTotals[day6].total_fat) : 0,
                dayTotals[day7] ? Math.round(dayTotals[day7].total_fat) : 0,
                dayTotals[day8] ? Math.round(dayTotals[day8].total_fat) : 0,
                dayTotals[day9] ? Math.round(dayTotals[day9].total_fat) : 0,
                dayTotals[day10] ? Math.round(dayTotals[day10].total_fat) : 0,
                dayTotals[day11] ? Math.round(dayTotals[day11].total_fat) : 0,
                dayTotals[day12] ? Math.round(dayTotals[day12].total_fat) : 0,
                dayTotals[day13] ? Math.round(dayTotals[day13].total_fat) : 0,
                dayTotals[day14] ? Math.round(dayTotals[day14].total_fat) : 0,
                dayTotals[day15] ? Math.round(dayTotals[day15].total_fat) : 0,
                dayTotals[day16] ? Math.round(dayTotals[day16].total_fat) : 0,
                dayTotals[day17] ? Math.round(dayTotals[day17].total_fat) : 0,
                dayTotals[day18] ? Math.round(dayTotals[day18].total_fat) : 0,
                dayTotals[day19] ? Math.round(dayTotals[day19].total_fat) : 0,
                dayTotals[day20] ? Math.round(dayTotals[day20].total_fat) : 0,
                dayTotals[day21] ? Math.round(dayTotals[day21].total_fat) : 0,
                dayTotals[day22] ? Math.round(dayTotals[day22].total_fat) : 0,
                dayTotals[day23] ? Math.round(dayTotals[day23].total_fat) : 0,
                dayTotals[day24] ? Math.round(dayTotals[day24].total_fat) : 0,
                dayTotals[day25] ? Math.round(dayTotals[day25].total_fat) : 0,
                dayTotals[day26] ? Math.round(dayTotals[day26].total_fat) : 0,
                dayTotals[day27] ? Math.round(dayTotals[day27].total_fat) : 0,
                dayTotals[day28] ? Math.round(dayTotals[day28].total_fat) : 0,
                dayTotals[day29] ? Math.round(dayTotals[day29].total_fat) : 0,
                dayTotals[day30] ? Math.round(dayTotals[day30].total_fat) : 0,
              ],
              backgroundColor: [
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
              ],
            },
            // {
            //   data: [
            //     dayTotals[day1] ? Math.round(dayTotals[day1].calories) : 0,
            //     dayTotals[day2] ? Math.round(dayTotals[day2].calories) : 0,
            //     dayTotals[day3] ? Math.round(dayTotals[day3].calories) : 0,
            //     dayTotals[day4] ? Math.round(dayTotals[day4].calories) : 0,
            //     dayTotals[day5] ? Math.round(dayTotals[day5].calories) : 0,
            //     dayTotals[day6] ? Math.round(dayTotals[day6].calories) : 0,
            //     dayTotals[day7] ? Math.round(dayTotals[day7].calories) : 0,
            //     dayTotals[day8] ? Math.round(dayTotals[day8].calories) : 0,
            //     dayTotals[day9] ? Math.round(dayTotals[day9].calories) : 0,
            //     dayTotals[day10] ? Math.round(dayTotals[day10].calories) : 0,
            //     dayTotals[day11] ? Math.round(dayTotals[day11].calories) : 0,
            //     dayTotals[day12] ? Math.round(dayTotals[day12].calories) : 0,
            //     dayTotals[day13] ? Math.round(dayTotals[day13].calories) : 0,
            //     dayTotals[day14] ? Math.round(dayTotals[day14].calories) : 0,
            //     dayTotals[day15] ? Math.round(dayTotals[day15].calories) : 0,
            //     dayTotals[day16] ? Math.round(dayTotals[day16].calories) : 0,
            //     dayTotals[day17] ? Math.round(dayTotals[day17].calories) : 0,
            //     dayTotals[day18] ? Math.round(dayTotals[day18].calories) : 0,
            //     dayTotals[day19] ? Math.round(dayTotals[day19].calories) : 0,
            //     dayTotals[day20] ? Math.round(dayTotals[day20].calories) : 0,
            //     dayTotals[day21] ? Math.round(dayTotals[day21].calories) : 0,
            //     dayTotals[day22] ? Math.round(dayTotals[day22].calories) : 0,
            //     dayTotals[day23] ? Math.round(dayTotals[day23].calories) : 0,
            //     dayTotals[day24] ? Math.round(dayTotals[day24].calories) : 0,
            //     dayTotals[day25] ? Math.round(dayTotals[day25].calories) : 0,
            //     dayTotals[day26] ? Math.round(dayTotals[day26].calories) : 0,
            //     dayTotals[day27] ? Math.round(dayTotals[day27].calories) : 0,
            //     dayTotals[day28] ? Math.round(dayTotals[day28].calories) : 0,
            //     dayTotals[day29] ? Math.round(dayTotals[day29].calories) : 0,
            //     dayTotals[day30] ? Math.round(dayTotals[day30].calories) : 0,
            //   ],
            //   type: 'line',
            //   label: 'Calories',
            //   xAxisID: 'bottom',
            //   fill: false,
            //   backgroundColor: [
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //     'rgba(0,0,0,0.7)',
            //   ],
            //   borderColor: 'rgba(0,0,0,0.7)',
            //   borderWidth: 1,
            //   snapGaps: false,
            // },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'Last 30 Days',
          },
          legend: {
            display: true,
            position: 'top',
          },
          tooltips: {
            enabled: true,
            displayColors: true,
            callbacks: {
              label: (tooltipItem, data) =>
                `${data.datasets[tooltipItem.datasetIndex].label}: ${
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ]
                }g`,
            },
          },
          scales: {
            xAxes: [
              {
                id: 'top',
                position: 'top',
                type: 'linear',
                stacked: true,
                ticks: {
                  callback: value => value.toLocaleString() + 'g',
                  beginAtZero: true,
                },
              },
              // {
              //   id: 'bottom',
              //   position: 'bottom',
              //   type: 'linear',
              //   ticks: {
              //     callback: value => value.toLocaleString() + ' cal',
              //     beginAtZero: true,
              //   },
              //   gridLines: {
              //     display: false,
              //   },
              // },
            ],
            yAxes: [
              {
                stacked: true,
                // ticks: {
                //   beginAtZero: true,
                // },
              },
            ],
          },
        },
      };
      // is getContext('2d') necessary? -- research
      new Chart(chartRef.current.getContext('2d'), config);
    };
    if (Object.keys(dayTotals).length > 0) drawChart();
  }, [
    day1,
    day10,
    day11,
    day12,
    day13,
    day14,
    day15,
    day16,
    day17,
    day18,
    day19,
    day2,
    day20,
    day21,
    day22,
    day23,
    day24,
    day25,
    day26,
    day27,
    day28,
    day29,
    day3,
    day30,
    day4,
    day5,
    day6,
    day7,
    day8,
    day9,
    dayArr,
    dayTotals,
  ]);

  return <canvas style={{ height: '900px' }} ref={chartRef} />;
};

export default ChartLastThirty;

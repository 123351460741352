const initialState = {
  isAuthenticated: false,
  user: {},
  isSigningUp: false,
  isLoggingIn: false,
  authError: false,
  isVerifying: false,
  verifyingError: false,
  isLoggingOut: false,
  logoutError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        isSigningUp: false,
        isAuthenticated: true,
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: true,
        user: action.user,
      };
    case 'LOGOUT_SUCCESS':
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
      };
    case 'SIGNUP_REQUEST':
      return {
        ...state,
        isSigningUp: true,
        authError: false,
      };
    case 'LOGIN_REQUEST':
      return {
        ...state,
        isLoggingIn: true,
        authError: false,
      };
    case 'LOGOUT_REQUEST':
      return {
        ...state,
        isLoggingOut: true,
        authError: false,
      };
    case 'SIGNUP_FAILURE':
      return {
        ...state,
        isSigningUp: false,
        isAuthenticated: false,
        authError: action.error,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        isLoggingIn: false,
        isAuthenticated: false,
        authError: action.error,
      };
    case 'LOGOUT_FAILURE':
      return {
        ...state,
        isLoggingOut: false,
        isAuthenticated: false,
        authError: action.error,
      };
    case 'VERIFY_REQUEST':
      return {
        ...state,
        isVerifying: true,
        verifyingError: false,
      };
    case 'VERIFY_SUCCESS':
      return {
        ...state,
        isVerifying: false,
      };
    default:
      return state;
  }
};

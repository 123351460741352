import { firebaseApp, db } from '../config/fbConfig';

const loginRequest = () => ({
  type: 'LOGIN_REQUEST',
});

const loginSuccess = user => ({
  type: 'LOGIN_SUCCESS',
  user,
});

const loginFailure = error => ({
  type: 'LOGIN_FAILURE',
  error,
});

const logoutRequest = () => ({
  type: 'LOGOUT_REQUEST',
});

const logoutSuccess = () => ({
  type: 'LOGOUT_SUCCESS',
});

const logoutFailure = error => ({
  type: 'LOGOUT_FAILURE',
  error,
});

const signupRequest = () => ({
  type: 'SIGNUP_REQUEST',
});

const signupSuccess = user => ({
  type: 'SIGNUP_SUCCESS',
  user,
});

const signupFailure = error => ({
  type: 'SIGNUP_FAILURE',
  error,
});

const verifyRequest = () => ({
  type: 'VERIFY_REQUEST',
});

const verifySuccess = () => ({
  type: 'VERIFY_SUCCESS',
});

export const verifyAuth = () => {
  return dispatch => {
    dispatch(verifyRequest());
    firebaseApp.auth().onAuthStateChanged(user => {
      if (user !== null) {
        dispatch(loginSuccess(user));
        db.collection('users')
          .doc(user.uid)
          .get()
          .then(doc => {
            dispatch({
              type: 'SAVE_CUSTOM_DV',
              payload: doc.data(),
            });
          })
          .catch(console.error);
        db.collection('meals')
          .where('userId', '==', user.uid)
          .orderBy('createdAt', 'desc')
          .limit(100)
          .get()
          .then(snapshot => {
            dispatch({
              type: 'LOAD_MEAL_HISTORY',
              snapshot,
            });
          })
          .then(() => {
            dispatch({ type: 'LOAD_DAY_TOTALS' });
          })
          .catch(console.error);
        db.collection('customlabels')
          .where('userId', '==', user.uid)
          .orderBy('createdAt', 'desc')
          .limit(100)
          .get()
          .then(snapshot => {
            dispatch({
              type: 'LOAD_CUSTOM_LABELS',
              snapshot,
            });
          })
          .catch(console.error);
      }
      dispatch(verifySuccess());
    });
  };
};

export const loginUser = (email, password) => {
  return dispatch => {
    dispatch(loginRequest());
    firebaseApp
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(user => {
        dispatch(loginSuccess(user));
      })
      .catch(error => {
        console.table({ error });
        dispatch(loginFailure(error.message));
      });
  };
};

export const signupUser = (email, password) => {
  return dispatch => {
    dispatch(signupRequest());
    firebaseApp
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(res => {
        dispatch(signupSuccess());
        return db
          .collection('users')
          .doc(res.user.uid)
          .set({
            email,
            createdAt: new Date(),
            calories: 2000,
            cholesterol: 300,
            dietary_fiber: 35,
            protein: 63,
            saturated_fat: 25,
            sodium: 2400,
            sugars: '',
            total_carbohydrate: 420,
            total_fat: 80,
          });
      })
      .catch(error => {
        console.table({ error });
        dispatch(signupFailure(error.message));
      });
  };
};

export const logOut = () => {
  return dispatch => {
    dispatch(logoutRequest());
    firebaseApp
      .auth()
      .signOut()
      .then(res => {
        dispatch(logoutSuccess());
      })
      .catch(error => {
        console.table({ error });
        dispatch(logoutFailure(error.message));
      });
  };
};

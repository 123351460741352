import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CustomCard from './CustomCard';
import Spinner from '../Spinner';
import Modal from '../Modal';
import { deleteCustomLabel } from '../../actions/nutritionActions';
import { emojiArr } from '../../helpers';
import ConfirmDelete from '../ConfirmDelete';

const CustomList = ({
  customLabels,
  mealsLoaded,
  deleteCustomLabel,
  mergeList,
  mergeAdd,
  mergeRemove,
  mergeClear,
  backArrowOff,
  history,
}) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const eventListenter = e => {
      const modalActive = document.getElementById('modal-delete');
      if (e.keyCode === 27) {
        // ESCAPE
        if (modalActive) setShowModal(false);
        clearCheckboxes();
      }
    };
    document.addEventListener('keydown', eventListenter);
    return () => {
      document.removeEventListener('keydown', eventListenter);
    };
  });

  useEffect(() => {
    backArrowOff();
    if (mergeList.length > 0) mergeClear();
  }, []); // eslint-disable-line

  const toggleModal = () => setShowModal(!showModal);

  const clearCheckboxes = () => {
    mergeClear();
    let checks = document.querySelectorAll('input:checked');
    checks.forEach(box => (box.checked = false));
  };

  const handleCheck = e => {
    if (e.target.checked) {
      mergeAdd(e.target.value);
    } else {
      mergeRemove(e.target.value);
    }
  };

  const handleClear = e => {
    e.preventDefault();
    clearCheckboxes();
  };

  const handleBatchedCustomLabelDelete = e => {
    e.preventDefault();
    toggleModal();
  };

  const confirmDelete = e => {
    e.preventDefault();
    mergeList.forEach(id => deleteCustomLabel(id));
    clearCheckboxes();
    toggleModal();
  };

  let labelList = customLabels.map(label => (
    <CustomCard
      meal={label}
      handleCheck={handleCheck}
      key={`${label.id}-custom`}
    />
  ));

  return (
    <div>
      <div className="custom-button-container">
        <button
          className="button-blue"
          id="create-label-create-button"
          type="submit"
          onClick={() => {
            history.push('/create');
          }}>
          Create Custom Label
        </button>
      </div>
      {mergeList.length === 1 ? (
        <div className="merge-container-custom">
          <button
            className="button-white"
            id="batch-delete-button"
            type="submit"
            onClick={handleBatchedCustomLabelDelete}>
            Delete {`(${mergeList.length})`}
          </button>
          <button
            className="button-white"
            id="clear-button"
            type="submit"
            onClick={handleClear}>
            Cancel
          </button>
        </div>
      ) : mergeList.length > 1 ? (
        <div className="merge-container-custom">
          <button
            className="button-white"
            id="batch-delete-button"
            type="submit"
            onClick={handleBatchedCustomLabelDelete}>
            Delete {`(${mergeList.length})`}
          </button>
          <button
            className="button-blue"
            id="merge-button"
            type="submit"
            onClick={() => {}}>
            Combine
          </button>
          <button
            className="button-white"
            id="clear-button"
            type="submit"
            onClick={handleClear}>
            Cancel
          </button>
        </div>
      ) : null}
      {labelList.length > 0 ? (
        <div>
          <div className="custom-label-container">{labelList}</div>
          <div className="center" style={{ position: 'relative', top: '60px' }}>
            ¯\_(ツ)_/¯{' '}
          </div>
        </div>
      ) : mealsLoaded === true ? (
        <div className="container">
          <div className="empty-log">
            <p>You have no custom labels :(</p>
            <br />
            <p>Let's make one!</p>
            <p>
              ༼∩☉_☉༽⊃━☆ﾟ. * ･ ｡ﾟ
              <span style={{ fontSize: '2.5rem' }}>
                {emojiArr[Math.floor(Math.random() * emojiArr.length)]}
              </span>
            </p>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      {showModal ? (
        <Modal>
          <ConfirmDelete
            toggleModal={toggleModal}
            confirmDelete={confirmDelete}
          />
        </Modal>
      ) : null}
    </div>
  );
};

const mapState = state => ({
  customLabels: state.nutrition.customLabels,
  mergeList: state.nutrition.mergeList,
  mealsLoaded: state.nutrition.mealsLoaded,
});

const mapDispatch = dispatch => ({
  mergeAdd: id => {
    dispatch({
      type: 'MERGELIST_ADD',
      id,
    });
  },
  mergeRemove: id => {
    dispatch({
      type: 'MERGELIST_REMOVE',
      id,
    });
  },
  mergeClear: () => {
    dispatch({ type: 'MERGELIST_CLEAR' });
  },
  deleteCustomLabel: id => dispatch(deleteCustomLabel(id)),
  backArrowOff: () => dispatch({ type: 'BACK_ARROW_OFF' }),
});

export default connect(mapState, mapDispatch)(CustomList);

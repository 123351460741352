import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { roundSubFive } from '../../helpers';

const MealCard = ({ meal, handleCheck, mergeList }) => {
  const [checked, setChecked] = useState(false);
  const [checkHover, setCheckHover] = useState(false);

  useEffect(() => {
    if (mergeList.length === 0) setChecked(false);
  }, [mergeList]);

  return (
    <>
      <div className="virtual-check">
        <div
          className={
            checked
              ? 'check-clicked'
              : checkHover
              ? 'check-hovered'
              : 'check-unclicked'
          }></div>
      </div>
      <div className="meal-card-check">
        <input
          type="checkbox"
          name="merge"
          id={`merge-${meal.id}`}
          value={meal.id}
          onMouseEnter={e => setCheckHover(true)}
          onMouseLeave={e => setCheckHover(false)}
          onChange={e => {
            handleCheck(e);
            setChecked(!checked);
          }}
        />
      </div>
      <Link to={`/custom/${meal.id}`}>
        <div className="meal-card" key={meal.id}>
          <div className="meal-card-cont1">
            <div className="meal-card-foodname">{meal.food_name}</div>
            <div className="meal-card-timestamp">
              {moment(meal.createdAt).format('LT')}
              <span style={{ marginLeft: '15px' }}>
                {!meal.serving_unit
                  ? meal.weight_grams
                    ? Math.round(meal.weight_grams) + 'g'
                    : 'Unavailable'
                  : meal.weight_grams > 0
                  ? `${Math.round(meal.serving_qty * 10) / 10} ${
                      meal.serving_unit
                    } (${Math.round(meal.weight_grams)}g)`
                  : `${Math.round(meal.serving_qty * 10) / 10} ${
                      meal.serving_unit
                    }`}
              </span>
            </div>
          </div>
          <div className="meal-card-cont2">
            <div className="meal-card-stats">
              {Math.round(meal.calories).toLocaleString()} cal
            </div>
            <div className="meal-card-stats">
              <small>Prot: </small>
              {roundSubFive(meal.protein)}
              <small>g</small>
            </div>
          </div>
          <div className="meal-card-cont2">
            <div className="meal-card-stats">
              <small>Fat: </small>
              {roundSubFive(meal.total_fat)}
              <small>g</small>
            </div>
            <div className="meal-card-stats">
              <small>Carb: </small>
              {roundSubFive(meal.total_carbohydrate)}
              <small>g</small>
            </div>
          </div>
          <div className="meal-card-cont3"></div>
        </div>
      </Link>
    </>
  );
};

const mapState = state => ({
  mergeList: state.nutrition.mergeList,
});

export default connect(mapState)(MealCard);

import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/layout/Navbar';
import Log from './components/home/Log';
import SideForm from './components/layout/SideForm';
import Support from './components/Support';
import LogIn from './components/LogIn';
import SideMenu from './components/layout/SideMenu';
import Account from './components/Account';
import Stats from './components/Stats';
import MealDetail from './components/home/MealDetail';
import CustomLabel from './components/custom/CustomLabel';
import CustomList from './components/custom/CustomList';
import CustomDetail from './components/custom/CustomDetail';
import DayDetail from './components/DayDetail';
import SimpleMeal from './components/SimpleMeal';
import ScrollToTop from './components/ScrollToTop';

const App = () => {
  return (
    <Router>
      <Fragment>
        <Navbar />
        <SideMenu />
        <SideForm />
        <ScrollToTop />
        <Switch>
          <Route path="/support" component={Support} />
          <Route path="/login" component={LogIn} />
          <Route path="/account" component={Account} />
          <Route path="/stats" component={Stats} />
          <Route path="/create" component={CustomLabel} />
          <Route path="/custom/:id" component={CustomDetail} />
          <Route path="/custom" component={CustomList} />
          <Route path="/simple" component={SimpleMeal} />
          <Route path="/day/:id" component={DayDetail} />
          <Route path="/:id" component={MealDetail} />
          <Route path="/" component={Log} />
        </Switch>
      </Fragment>
    </Router>
  );
};

export default App;

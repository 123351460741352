import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';

const ChartMacros = ({ total }) => {
  // needed to reference the canvas element when the chart is created
  const chartRef = useRef(null);

  useEffect(() => {
    const drawChart = () => {
      const config = {
        type: 'doughnut',
        data: {
          datasets: [
            {
              data: [
                `${Math.round(total.protein)}`,
                `${Math.round(total.total_fat)}`,
                `${Math.round(total.total_carbohydrate)}`,
              ],
              backgroundColor: [
                'rgba(140,21,21,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(0,155,60,0.3)',
              ],
            },
          ],
          labels: ['Protein', 'Fat', 'Carbs'],
        },
        options: {
          responsive: true,
          legend: {
            display: false,
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Macro Distribution',
          },
          animation: {
            animateScale: true,
            animateRotate: true,
          },
          tooltips: {
            enabled: true,
            displayColors: true,
            callbacks: {
              label: (tooltipItem, data) =>
                ` ${data.labels[tooltipItem.index]}: ${
                  data.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ]
                }g`,
            },
          },
        },
      };
      // is getContext('2d') necessary? -- research
      new Chart(chartRef.current.getContext('2d'), config);
    };
    drawChart();
  }, [total]);

  return <canvas ref={chartRef} />;
};

export default ChartMacros;

import React from 'react';

export default function CustomInput({ field, unit, handleCustom }) {
  return (
    <div>
      <form autoComplete="off" onSubmit={e => e.preventDefault()}>
        <input
          className="browser-default outline-input"
          placeholder={unit}
          id={field}
          type="number"
          inputMode="decimal"
          min="0"
          onChange={handleCustom}
        />
      </form>
    </div>
  );
}

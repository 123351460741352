import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import moment from 'moment';
import Modal from '../Modal';
import ConfirmDelete from '../ConfirmDelete';
import { dailyValues, roundSubFive } from '../../helpers';
import {
  saveMeal,
  deleteCustomLabel,
  editCustomMealName,
} from '../../actions/nutritionActions';

const Meals = ({
  customLabels,
  userSavedDv,
  saveMeal,
  deleteCustomLabel,
  editCustomMealName,
  backArrowOn,
  history,
}) => {
  const [mealName, setMealName] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    backArrowOn();
  }, [backArrowOn]);

  let { id } = useParams();
  let label = customLabels.filter(label => label.id === id);
  label = label[0];

  const toggleModal = () => setShowModal(!showModal);

  const handleDuplicate = e => {
    e.preventDefault();
    saveMeal(label);
    history.push('/');
  };

  const handleDelete = e => {
    e.preventDefault();
    toggleModal();
  };

  const confirmDelete = e => {
    e.preventDefault();
    deleteCustomLabel(id);
    history.push('/custom');
  };

  const handleNameChange = e => {
    setMealName(e.target.value);
  };

  const handleEditSubmit = e => {
    e.preventDefault();

    if (mealName) {
      editCustomMealName(id, mealName);
      setMealName(null);
      let el = document.getElementById('textarea1');
      el.value = '';
    }

    history.push('/custom');
  };

  if (label !== undefined) {
    // backArrowOn();
    return (
      <div>
        {label.photo &&
        label.photo !==
          'https://d2eawub7utcl6.cloudfront.net/images/nix-apple-grey.png' ? (
          <div className="label-thumb-cont">
            <img src={label.photo} alt="food" />
          </div>
        ) : null}

        <div className="meal-review-container nutrition-display">
          <div className="nutrition-facts">
            <div>
              <span className="nutrition-facts-title">{label.food_name}</span>
            </div>
            <table>
              <tbody>
                <tr className="thick-row mini-header">
                  <td colSpan="5">
                    Serving:{' '}
                    {!label.serving_unit
                      ? label.weight_grams
                        ? Math.round(label.weight_grams) + 'g'
                        : 'Unavailable'
                      : label.weight_grams > 0
                      ? `${Math.round(label.serving_qty * 10) / 10} ${
                          label.serving_unit
                        } (${Math.round(label.weight_grams)}g)`
                      : `${Math.round(label.serving_qty * 10) / 10} ${
                          label.serving_unit
                        }`}
                  </td>
                </tr>
                <tr className="mini-header">
                  <td colSpan="2">Amount</td>
                  <th colSpan="2"></th>
                  <th>% DV*</th>
                </tr>
                <tr className="thick-row">
                  <th colSpan="3">
                    Calories{' '}
                    <span className="calories">
                      {label.calories.toLocaleString()}
                    </span>
                  </th>
                  <td className="calories"></td>
                  <td>{dailyValues(label.calories, userSavedDv.calories)}</td>
                </tr>
                <tr>
                  <th colSpan="3">
                    Total Fat {roundSubFive(label.total_fat)}g
                  </th>
                  <td></td>
                  <td>{dailyValues(label.total_fat, userSavedDv.total_fat)}</td>
                </tr>
                <tr>
                  <td colSpan="3" className="sublabel">
                    Saturated Fat {roundSubFive(label.saturated_fat)}g
                  </td>
                  <td></td>
                  <td>
                    {dailyValues(
                      label.saturated_fat,
                      userSavedDv.saturated_fat
                    )}
                  </td>
                </tr>
                <tr>
                  <th colSpan="3">
                    Cholesterol {Math.round(label.cholesterol)}
                    mg
                  </th>
                  <td></td>
                  <td>
                    {dailyValues(label.cholesterol, userSavedDv.cholesterol)}
                  </td>
                </tr>
                <tr>
                  <th colSpan="3">
                    Sodium {Math.round(label.sodium).toLocaleString()}
                    mg
                  </th>
                  <td></td>
                  <td>{dailyValues(label.sodium, userSavedDv.sodium)}</td>
                </tr>
                <tr>
                  <th colSpan="3">
                    Total Carbs {roundSubFive(label.total_carbohydrate)}g
                  </th>
                  <td></td>
                  <td>
                    {dailyValues(
                      label.total_carbohydrate,
                      userSavedDv.total_carbohydrate
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" className="sublabel">
                    Dietary Fiber {roundSubFive(label.dietary_fiber)}g
                  </td>
                  <td></td>
                  <td>
                    {dailyValues(
                      label.dietary_fiber,
                      userSavedDv.dietary_fiber
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" className="sublabel">
                    Sugars {roundSubFive(label.sugars)}g
                  </td>
                  <td></td>
                  <td>{dailyValues(label.sugars, userSavedDv.sugars)}</td>
                </tr>
                <tr className="thick-row">
                  <th colSpan="3">Protein {roundSubFive(label.protein)}g</th>
                  <td></td>
                  <td>{dailyValues(label.protein, userSavedDv.protein)}</td>
                </tr>
                <tr className="mini-header">
                  <td colSpan="5" className="no-underline">
                    * Daily Values based on a{' '}
                    {userSavedDv.calories.toLocaleString()} calorie diet.{' '}
                    {
                      <Link to="/account" className="sidenav-close">
                        Click here
                      </Link>
                    }{' '}
                    to customize values
                  </td>
                  <td className="no-underline"></td>
                </tr>
                {label.ingredients ? (
                  <tr className="mini-header">
                    <td colSpan="5" className="no-underline">
                      {`Ingredients: ${label.ingredients}`}
                    </td>
                  </tr>
                ) : null}
                <tr className="mini-header no-underline">
                  <td
                    colSpan="5"
                    className="no-underline"
                    style={{ textAlign: 'center' }}>
                    Created:{' '}
                    {`${moment(label.createdAt).format('dddd')}, ${moment(
                      label.createdAt
                    )
                      .format('l')
                      .replace('2019', '19')} @ ${moment(
                      label.createdAt
                    ).format('LT')}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="meal-mod-container">
          <button
            className="button-blue"
            id={`${label.id}-dupe`}
            type="submit"
            onClick={handleDuplicate}>
            Eat Again!
          </button>

          <div className="edit-container">
            <h6>Modify Custom Label</h6>
            <form onSubmit={handleEditSubmit}>
              <div className="input-field textarea-container">
                <textarea
                  onChange={handleNameChange}
                  id="textarea1"
                  className="materialize-textarea"></textarea>
                <label style={{ color: '#9e9e9e' }} htmlFor="textarea1">
                  Meal Name
                </label>
              </div>
              <div className="date-time-container"></div>
              <button className="button-white" id="edit-button" type="submit">
                Save Changes
              </button>
            </form>
          </div>
          <button
            className="button-white delete-button"
            type="submit"
            onClick={handleDelete}>
            Delete Record
          </button>
        </div>
        {showModal ? (
          <Modal>
            <ConfirmDelete
              toggleModal={toggleModal}
              confirmDelete={confirmDelete}
            />
          </Modal>
        ) : null}
      </div>
    );
  } else return null;
};

const mapState = state => ({
  customLabels: state.nutrition.customLabels,
  userSavedDv: state.nutrition.userSavedDv,
});

const mapDispatch = dispatch => ({
  deleteCustomLabel: id => dispatch(deleteCustomLabel(id)),
  saveMeal: meal => dispatch(saveMeal(meal)),
  editCustomMealName: (id, name) => dispatch(editCustomMealName(id, name)),
  backArrowOn: () => dispatch({ type: 'BACK_ARROW_ON' }),
});

export default connect(mapState, mapDispatch)(Meals);

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { saveMeal } from '../../actions/nutritionActions';
import { properCase, roundSubFive, dailyValues } from '../../helpers';

const NutritionLabel = ({ food, userSavedDv, saveMeal, isAuthenticated }) => {
  const [userInput, setUserInput] = useState(null);
  const [customWeight, setCustomWeight] = useState(null);
  const [conversion, setConversion] = useState(null);
  const [customLabel, setCustomLabel] = useState(null);
  const [showLabel, setShowLabel] = useState(true);
  const [selectIndex, setSelectIndex] = useState('0');

  useEffect(() => {
    resetCustom();
    setShowLabel(true);
  }, [food]);

  useEffect(() => {
    const eventListenter = e => {
      let el = document.getElementById('label-close');
      if (el && e.keyCode === 27) {
        // ESCAPE
        el.click();
      }
    };
    document.addEventListener('keydown', eventListenter);
    return () => {
      document.removeEventListener('keydown', eventListenter);
    };
  });

  const resetCustom = () => {
    const el = document.getElementById('gram-input');
    if (el) el.value = '';
    setCustomLabel(null);
    setConversion(null);
    setCustomWeight(null);
    setUserInput(null);
    setSelectIndex('0');
  };

  if (food && showLabel) {
    const showCustomCol =
      (food.serving_weight_grams && customWeight > 0) ||
      (selectIndex === '0' && conversion > 0)
        ? true
        : false;

    let foodName = null;
    if (food.brand_name) {
      foodName = `${food.brand_name} ${food.food_name}`;
    } else {
      foodName = properCase(food.food_name);
    }

    const handleSubmit = e => {
      e.preventDefault();
      // if conversion is null, then there has been no custom change to the input field
      if (!conversion) {
        const oneServing = {
          food_name: foodName,
          weight_grams: +food.serving_weight_grams || '',
          serving_qty: +food.serving_qty || '',
          serving_unit: food.serving_unit || '',
          calories: +roundSubFive(food.nf_calories) || 0,
          total_fat: +roundSubFive(food.nf_total_fat) || 0,
          saturated_fat: +roundSubFive(food.nf_saturated_fat) || 0,
          cholesterol: +Math.round(food.nf_cholesterol) || 0,
          sodium: +Math.round(food.nf_sodium) || 0,
          total_carbohydrate: +roundSubFive(food.nf_total_carbohydrate) || 0,
          dietary_fiber: +roundSubFive(food.nf_dietary_fiber) || 0,
          sugars: +roundSubFive(food.nf_sugars) || 0,
          protein: +roundSubFive(food.nf_protein) || 0,
        };
        saveMeal({ ...oneServing, photo: food.photo.thumb });
      } else saveMeal({ ...customLabel, photo: food.photo.thumb });
      resetCustom();
      setShowLabel(false);
      document.getElementById('hidden-sidenav-close-button').click();
    };

    // handleSelect comment below explains why added num and index parameters.
    const handleCustomChange = (e, num, index) => {
      let val = null;
      let i = index;
      if (e) {
        val = e.target.value;
        i = selectIndex;
        setUserInput(val);
      }
      // select is toggled to 'grams'
      if (i === '1') {
        const grams = val || num;
        const convert = grams / food.serving_weight_grams;
        setCustomWeight(grams);
        setConversion(convert);
        setCustomLabel({
          food_name: foodName,
          weight_grams: +grams,
          serving_unit: food.serving_unit || '',
          serving_qty:
            +roundSubFive(
              (grams / food.serving_weight_grams) * food.serving_qty
            ) || '',
          calories: +roundSubFive(convert * food.nf_calories) || 0,
          total_fat: +roundSubFive(convert * food.nf_total_fat) || 0,
          saturated_fat: +roundSubFive(convert * food.nf_saturated_fat) || 0,
          cholesterol: +Math.round(convert * food.nf_cholesterol) || 0,
          sodium: +Math.round(convert * food.nf_sodium) || 0,
          total_carbohydrate:
            +roundSubFive(convert * food.nf_total_carbohydrate) || 0,
          dietary_fiber: +roundSubFive(convert * food.nf_dietary_fiber) || 0,
          sugars: +roundSubFive(convert * food.nf_sugars) || 0,
          protein: +roundSubFive(convert * food.nf_protein) || 0,
        });
      }
      // select is on 'servings' (default)
      if (i === '0') {
        const servings = val || num;
        setCustomWeight(servings * food.serving_weight_grams);
        setConversion(servings);
        setCustomLabel({
          food_name: foodName,
          weight_grams: servings * food.serving_weight_grams || '',
          serving_unit: food.serving_unit || '',
          serving_qty: +roundSubFive(servings * food.serving_qty) || '',
          calories: +roundSubFive(servings * food.nf_calories) || 0,
          total_fat: +roundSubFive(servings * food.nf_total_fat) || 0,
          saturated_fat: +roundSubFive(servings * food.nf_saturated_fat) || 0,
          cholesterol: +Math.round(servings * food.nf_cholesterol) || 0,
          sodium: +Math.round(servings * food.nf_sodium) || 0,
          total_carbohydrate:
            +roundSubFive(servings * food.nf_total_carbohydrate) || 0,
          dietary_fiber: +roundSubFive(servings * food.nf_dietary_fiber) || 0,
          sugars: +roundSubFive(servings * food.nf_sugars) || 0,
          protein: +roundSubFive(servings * food.nf_protein) || 0,
        });
      }
    };

    const handleSelect = e => {
      let val = e.target.value;
      setSelectIndex(val);
      handleCustomChange(null, userInput, val); // firing off customChange when select is toggled and input already contains a value. Complex because useState asynchronicity, so had to add two additional parameters to handleCustomChange and first test if the CustomChange is an input event or select event
    };

    return (
      <div className="nutrition-display nutrition-display-side-nav">
        <div className="nutrition-facts">
          <div>
            <span className="nutrition-facts-title">
              {foodName.length <= 100
                ? foodName
                : foodName.substring(0, 99) + '...'}
            </span>
            <span>
              <button
                className="browser-default"
                id="label-close"
                onClick={() => setShowLabel(false)}>
                CLOSE
              </button>
            </span>
          </div>
          <table>
            <tbody>
              <tr className="thick-row mini-header">
                <td colSpan="5">
                  Serving size:{' '}
                  {`${Math.round(food.serving_qty * 100) / 100} ${
                    food.serving_unit
                  }`}
                  {food.serving_weight_grams
                    ? ' (' + Math.round(food.serving_weight_grams) + 'g)'
                    : ''}
                </td>
              </tr>
              <tr className="mini-header">
                <td colSpan="2">Amount Per Serving</td>
                <th colSpan="2">{showCustomCol ? 'Custom' : ''}</th>
                <th>% DV*</th>
              </tr>
              <tr className="thick-row">
                <th colSpan="3">
                  Calories{' '}
                  <span className="calories">
                    {typeof food.nf_calories === 'number'
                      ? roundSubFive(food.nf_calories)
                      : '0'}
                  </span>
                </th>
                <td className="calories">
                  {showCustomCol ? customLabel.calories.toLocaleString() : ''}
                </td>
                <td>
                  {showCustomCol
                    ? dailyValues(
                        food.nf_calories,
                        userSavedDv.calories,
                        conversion
                      )
                    : typeof food.nf_calories === 'number'
                    ? dailyValues(food.nf_calories, userSavedDv.calories)
                    : '-'}
                </td>
              </tr>
              <tr>
                <th colSpan="3">
                  Total Fat{' '}
                  {typeof food.nf_total_fat === 'number'
                    ? roundSubFive(food.nf_total_fat)
                    : '0'}
                  g
                </th>
                <td>{showCustomCol ? `${customLabel.total_fat}g` : ''}</td>
                <td>
                  {showCustomCol
                    ? dailyValues(
                        food.nf_total_fat,
                        userSavedDv.total_fat,
                        conversion
                      )
                    : typeof food.nf_total_fat === 'number'
                    ? dailyValues(food.nf_total_fat, userSavedDv.total_fat)
                    : '-'}
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="sublabel">
                  Saturated Fat{' '}
                  {typeof food.nf_saturated_fat === 'number'
                    ? roundSubFive(food.nf_saturated_fat)
                    : '0'}
                  g
                </td>
                <td>{showCustomCol ? `${customLabel.saturated_fat}g` : ''}</td>
                <td>
                  {showCustomCol
                    ? dailyValues(
                        food.nf_saturated_fat,
                        userSavedDv.saturated_fat,
                        conversion
                      )
                    : typeof food.nf_saturated_fat === 'number'
                    ? dailyValues(
                        food.nf_saturated_fat,
                        userSavedDv.saturated_fat
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <th colSpan="3">
                  Cholesterol{' '}
                  {typeof food.nf_cholesterol === 'number'
                    ? Math.round(food.nf_cholesterol)
                    : '0'}
                  mg
                </th>
                <td>{showCustomCol ? `${customLabel.cholesterol}mg` : ''}</td>
                <td>
                  {showCustomCol
                    ? dailyValues(
                        food.nf_cholesterol,
                        userSavedDv.cholesterol,
                        conversion
                      )
                    : typeof food.nf_cholesterol === 'number'
                    ? dailyValues(food.nf_cholesterol, userSavedDv.cholesterol)
                    : '-'}
                </td>
              </tr>
              <tr>
                <th colSpan="3">
                  Sodium{' '}
                  {typeof food.nf_sodium === 'number'
                    ? Math.round(food.nf_sodium)
                    : '0'}
                  mg
                </th>
                <td>{showCustomCol ? `${customLabel.sodium}mg` : ''}</td>
                <td>
                  {showCustomCol
                    ? dailyValues(
                        food.nf_sodium,
                        userSavedDv.sodium,
                        conversion
                      )
                    : typeof food.nf_sodium === 'number'
                    ? dailyValues(food.nf_sodium, userSavedDv.sodium)
                    : '-'}
                </td>
              </tr>
              <tr>
                <th colSpan="3">
                  Total Carbs{' '}
                  {typeof food.nf_total_carbohydrate === 'number'
                    ? roundSubFive(food.nf_total_carbohydrate)
                    : '0'}
                  g
                </th>
                <td>
                  {showCustomCol ? `${customLabel.total_carbohydrate}g` : ''}
                </td>
                <td>
                  {showCustomCol
                    ? dailyValues(
                        food.nf_total_carbohydrate,
                        userSavedDv.total_carbohydrate,
                        conversion
                      )
                    : typeof food.nf_total_carbohydrate === 'number'
                    ? dailyValues(
                        food.nf_total_carbohydrate,
                        userSavedDv.total_carbohydrate
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="sublabel">
                  Dietary Fiber{' '}
                  {typeof food.nf_dietary_fiber === 'number'
                    ? roundSubFive(food.nf_dietary_fiber)
                    : '0'}
                  g
                </td>
                <td>{showCustomCol ? `${customLabel.dietary_fiber}g` : ''}</td>
                <td>
                  {showCustomCol
                    ? dailyValues(
                        food.nf_dietary_fiber,
                        userSavedDv.dietary_fiber,
                        conversion
                      )
                    : typeof food.nf_dietary_fiber === 'number'
                    ? dailyValues(
                        food.nf_dietary_fiber,
                        userSavedDv.dietary_fiber
                      )
                    : '-'}
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="sublabel">
                  Sugars{' '}
                  {typeof food.nf_sugars === 'number'
                    ? roundSubFive(food.nf_sugars)
                    : '0'}
                  g
                </td>
                <td>{showCustomCol ? `${customLabel.sugars}g` : ''}</td>
                <td>
                  {showCustomCol
                    ? dailyValues(
                        food.nf_sugars,
                        userSavedDv.sugars,
                        conversion
                      )
                    : typeof food.nf_sugars === 'number'
                    ? dailyValues(food.nf_sugars, userSavedDv.sugars)
                    : '-'}
                </td>
              </tr>
              <tr className="thick-row">
                <th colSpan="3">
                  Protein{' '}
                  {typeof food.nf_protein === 'number'
                    ? roundSubFive(food.nf_protein)
                    : '0'}
                  g
                </th>
                <td>{showCustomCol ? `${customLabel.protein}g` : ''}</td>
                <td>
                  {showCustomCol
                    ? dailyValues(
                        food.nf_protein,
                        userSavedDv.protein,
                        conversion
                      )
                    : typeof food.nf_protein === 'number'
                    ? dailyValues(food.nf_protein, userSavedDv.protein)
                    : '-'}
                </td>
              </tr>
              <tr className="mini-header no-underline">
                <td colSpan="5" className="no-underline">
                  * Daily Values based on a{' '}
                  {userSavedDv.calories.toLocaleString()} calorie diet.{' '}
                  {isAuthenticated ? (
                    <Link to="/account" className="sidenav-close">
                      Click here
                    </Link>
                  ) : (
                    <Link to="/login" className="sidenav-close">
                      Log in
                    </Link>
                  )}{' '}
                  to customize values
                </td>
                <td className="no-underline"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="custom-add-form">
          <form autoComplete="off" onSubmit={handleSubmit}>
            <input
              className="gram-input browser-default"
              placeholder="custom"
              id="custom-gram-input"
              type="number"
              inputMode="decimal"
              min="0"
              step="0.01"
              onChange={handleCustomChange}
            />
            <button className="button-blue" id="add-button">
              Add
            </button>
          </form>
          <select
            id="unit-select"
            className="browser-default"
            disabled={!food.serving_weight_grams}
            onChange={handleSelect}>
            <option value="0">servings</option>
            <option value="1">grams</option>
          </select>
        </div>
        <button
          className="sidenav-close"
          id="hidden-sidenav-close-button"></button>
      </div>
    );
  }
  return null;
};

const mapState = state => ({
  userSavedDv: state.nutrition.userSavedDv,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatch = dispatch => ({
  saveMeal: meal => dispatch(saveMeal(meal)),
});

export default connect(mapState, mapDispatch)(NutritionLabel);

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { logOut } from '../../actions/authActions';

const SideMenu = ({ isAuthenticated, logOut }) => {
  useEffect(() => {
    const eventListenter = e => {
      let el = document.getElementById('hidden-menu-close-button');
      if (el && e.keyCode === 27) {
        // ESCAPE
        el.click();
      }
    };
    document.addEventListener('keydown', eventListenter);
    return () => {
      document.removeEventListener('keydown', eventListenter);
    };
  });

  return (
    <div>
      <ul id="side-menu" className="sidenav side-menu">
        {isAuthenticated ? (
          <>
            <li>
              <Link to="/" className="waves-effect sidenav-close">
                <i className="material-icons">home</i>
                Home
              </Link>
            </li>
            <li>
              <Link to="/account" className="waves-effect sidenav-close">
                <i className="material-icons">account_circle</i>
                Account
              </Link>
            </li>
            <li>
              <Link to="/stats" className="waves-effect sidenav-close">
                <i className="material-icons">show_chart</i>
                Stats
              </Link>
            </li>
            <li>
              <Link to="/custom" className="waves-effect sidenav-close">
                <i className="material-icons">post_add</i>
                Custom Labels
              </Link>
            </li>
            <li>
              <Link to="/support" className="waves-effect sidenav-close">
                <i className="material-icons">help_outline</i>Support
              </Link>
            </li>
            <li>
              <Link
                to="/login"
                onClick={logOut}
                className="waves-effect sidenav-close">
                <i className="material-icons">exit_to_app</i>Log Out
              </Link>
            </li>
          </>
        ) : (
          <>
            <li>
              <Link to="/login" className="waves-effect sidenav-close">
                <i className="material-icons">account_circle</i>
                Log In
              </Link>
            </li>
            <li>
              <Link to="/support" className="waves-effect sidenav-close">
                <i className="material-icons">help_outline</i>Support
              </Link>
            </li>
          </>
        )}
        <button
          className="sidenav-close"
          id="hidden-menu-close-button"></button>
      </ul>
    </div>
  );
};

const mapState = state => ({
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatch = dispatch => ({
  logOut: () => dispatch(logOut()),
});

export default connect(mapState, mapDispatch)(SideMenu);

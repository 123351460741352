import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import moment from 'moment';

const ChartLastSeven = ({ dayTotals }) => {
  // needed to reference the canvas element when the chart is created
  const chartRef = useRef(null);
  const today = new Date();
  const day1 = moment(today)
    .subtract(6, 'days')
    .format('l');
  const day2 = moment(today)
    .subtract(5, 'days')
    .format('l');
  const day3 = moment(today)
    .subtract(4, 'days')
    .format('l');
  const day4 = moment(today)
    .subtract(3, 'days')
    .format('l');
  const day5 = moment(today)
    .subtract(2, 'days')
    .format('l');
  const day6 = moment(today)
    .subtract(1, 'days')
    .format('l');
  const day7 = moment(today).format('l');
  const dayArr = [
    day1.replace('2020', '20'),
    day2.replace('2020', '20'),
    day3.replace('2020', '20'),
    day4.replace('2020', '20'),
    day5.replace('2020', '20'),
    day6.replace('2020', '20'),
    day7.replace('2020', '20'),
  ];

  useEffect(() => {
    const drawChart = () => {
      const config = {
        type: 'bar',
        data: {
          labels: dayArr,
          datasets: [
            {
              label: 'Protein',
              data: [
                dayTotals[day1] ? Math.round(dayTotals[day1].protein) : 0,
                dayTotals[day2] ? Math.round(dayTotals[day2].protein) : 0,
                dayTotals[day3] ? Math.round(dayTotals[day3].protein) : 0,
                dayTotals[day4] ? Math.round(dayTotals[day4].protein) : 0,
                dayTotals[day5] ? Math.round(dayTotals[day5].protein) : 0,
                dayTotals[day6] ? Math.round(dayTotals[day6].protein) : 0,
                dayTotals[day7] ? Math.round(dayTotals[day7].protein) : 0,
              ],
              backgroundColor: [
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(140,21,21,0.3)',
              ],
            },
            {
              label: 'Carbs',
              data: [
                dayTotals[day1]
                  ? Math.round(dayTotals[day1].total_carbohydrate)
                  : 0,
                dayTotals[day2]
                  ? Math.round(dayTotals[day2].total_carbohydrate)
                  : 0,
                dayTotals[day3]
                  ? Math.round(dayTotals[day3].total_carbohydrate)
                  : 0,
                dayTotals[day4]
                  ? Math.round(dayTotals[day4].total_carbohydrate)
                  : 0,
                dayTotals[day5]
                  ? Math.round(dayTotals[day5].total_carbohydrate)
                  : 0,
                dayTotals[day6]
                  ? Math.round(dayTotals[day6].total_carbohydrate)
                  : 0,
                dayTotals[day7]
                  ? Math.round(dayTotals[day7].total_carbohydrate)
                  : 0,
              ],
              backgroundColor: [
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
                'rgba(0,155,60,0.3)',
              ],
            },
            {
              label: 'Fat',
              data: [
                dayTotals[day1] ? Math.round(dayTotals[day1].total_fat) : 0,
                dayTotals[day2] ? Math.round(dayTotals[day2].total_fat) : 0,
                dayTotals[day3] ? Math.round(dayTotals[day3].total_fat) : 0,
                dayTotals[day4] ? Math.round(dayTotals[day4].total_fat) : 0,
                dayTotals[day5] ? Math.round(dayTotals[day5].total_fat) : 0,
                dayTotals[day6] ? Math.round(dayTotals[day6].total_fat) : 0,
                dayTotals[day7] ? Math.round(dayTotals[day7].total_fat) : 0,
              ],
              backgroundColor: [
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(77,79,83,0.3)',
              ],
            },
            {
              data: [
                dayTotals[day1] ? Math.round(dayTotals[day1].calories) : 0,
                dayTotals[day2] ? Math.round(dayTotals[day2].calories) : 0,
                dayTotals[day3] ? Math.round(dayTotals[day3].calories) : 0,
                dayTotals[day4] ? Math.round(dayTotals[day4].calories) : 0,
                dayTotals[day5] ? Math.round(dayTotals[day5].calories) : 0,
                dayTotals[day6] ? Math.round(dayTotals[day6].calories) : 0,
                dayTotals[day7] ? Math.round(dayTotals[day7].calories) : 0,
              ],
              type: 'line',
              label: 'Calories',
              yAxisID: 'right',
              fill: false,
              backgroundColor: 'rgba(0,0,0,0.7)',
              borderColor: 'rgba(0,0,0,0.7)',
              borderWidth: 1,
              snapGaps: false,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: true,
            text: 'Last 7 Days',
          },
          legend: {
            display: true,
            position: 'top',
          },
          tooltips: {
            enabled: true,
            displayColors: true,
            // callbacks: {
            //   label: (tooltipItem, data) =>
            //     `${data.datasets[tooltipItem.datasetIndex].label}: ${
            //       data.datasets[tooltipItem.datasetIndex].data[
            //         tooltipItem.index
            //       ]
            //     }g`,
            // },
          },
          scales: {
            yAxes: [
              {
                id: 'left',
                stacked: true,
                ticks: {
                  callback: value => value.toLocaleString() + 'g',
                  beginAtZero: true,
                },
              },
              {
                id: 'right',
                position: 'right',
                ticks: {
                  callback: value => value.toLocaleString() + ' cal',
                },
                gridLines: {
                  display: false,
                },
              },
            ],
            xAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      };
      // is getContext('2d') necessary? -- research
      new Chart(chartRef.current.getContext('2d'), config);
    };
    if (Object.keys(dayTotals).length > 0) drawChart();
  }, [day1, day2, day3, day4, day5, day6, day7, dayArr, dayTotals]);

  return <canvas style={{ height: '300px' }} ref={chartRef} />;
};

export default ChartLastSeven;

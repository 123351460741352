import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import ChartLastSeven from './ChartLastSeven';
import ChartLastThirty from './ChartLastThirty';

const Stats = ({ userMeals }) => {
  let dayTotals = {};
  for (let i = 0; i < userMeals.length; i++) {
    let formatted = moment(userMeals[i].createdAt).format('l');
    if (!dayTotals[formatted]) dayTotals[formatted] = {};
  }

  for (const day in dayTotals) {
    let daysTotal = userMeals.reduce(
      (acc, cur) => {
        if (moment(cur.createdAt).format('l') === day) {
          return {
            meals: acc.meals + 1,
            calories: acc.calories + cur.calories,
            cholesterol: acc.cholesterol + cur.cholesterol,
            dietary_fiber: acc.dietary_fiber + cur.dietary_fiber,
            protein: acc.protein + cur.protein,
            sugars: acc.sugars + cur.sugars,
            sodium: acc.sodium + cur.sodium,
            total_fat: acc.total_fat + cur.total_fat,
            total_carbohydrate: acc.total_carbohydrate + cur.total_carbohydrate,
            saturated_fat: acc.saturated_fat + cur.saturated_fat,
          };
        } else
          return {
            meals: acc.meals,
            calories: acc.calories,
            cholesterol: acc.cholesterol,
            dietary_fiber: acc.dietary_fiber,
            protein: acc.protein,
            sugars: acc.sugars,
            sodium: acc.sodium,
            total_fat: acc.total_fat,
            total_carbohydrate: acc.total_carbohydrate,
            saturated_fat: acc.saturated_fat,
          };
      },
      {
        meals: 0,
        calories: 0,
        cholesterol: 0,
        dietary_fiber: 0,
        protein: 0,
        sugars: 0,
        sodium: 0,
        total_fat: 0,
        total_carbohydrate: 0,
        saturated_fat: 0,
      }
    );
    dayTotals[day] = daysTotal;
  }
  return (
    <div>
      <div className="daychart-container">
        <ChartLastSeven dayTotals={dayTotals} />
      </div>
      <hr />
      <div className="daychart-container">
        <ChartLastThirty dayTotals={dayTotals} />
      </div>
    </div>
  );
};

const mapState = state => ({
  userMeals: state.nutrition.userMeals,
});

export default connect(mapState)(Stats);

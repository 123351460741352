import moment from 'moment';

const initialState = {
  userSavedDv: {
    calories: 2000,
    total_fat: 65,
    saturated_fat: 20,
    cholesterol: 300,
    sodium: 2400,
    total_carbohydrate: 300,
    dietary_fiber: 25,
    sugars: 100,
    protein: 50,
  },
  mealsLoaded: false,
  userMeals: [],
  userDayTotals: {},
  customLabels: [],
  mergeList: [],
};

export default (state = initialState, action) => {
  let mergeList;
  let userMeals;
  let updatedMeals;
  let customLabels;
  let updatedLabels;

  switch (action.type) {
    case 'LOAD_MEAL_HISTORY':
      userMeals = state.userMeals.slice();
      action.snapshot.forEach(doc => {
        userMeals.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return {
        ...state,
        userMeals,
        mealsLoaded: true,
      };
    // userDayTotals not currently used -- test further and remove from reducer if not usable
    case 'LOAD_DAY_TOTALS':
      let userDayTotals = {};
      userMeals = state.userMeals.slice();
      for (let i = 0; i < userMeals.length; i++) {
        let formatted = moment(userMeals[i].createdAt).format('l');
        if (!userDayTotals[formatted]) userDayTotals[formatted] = {};
      }

      for (const day in userDayTotals) {
        let daysTotal = userMeals.reduce(
          (acc, cur) => {
            if (moment(cur.createdAt).format('l') === day) {
              return {
                meals: acc.meals + 1,
                calories: acc.calories + cur.calories,
                cholesterol: acc.cholesterol + cur.cholesterol,
                dietary_fiber: acc.dietary_fiber + cur.dietary_fiber,
                protein: acc.protein + cur.protein,
                sugars: acc.sugars + cur.sugars,
                sodium: acc.sodium + cur.sodium,
                total_fat: acc.total_fat + cur.total_fat,
                total_carbohydrate:
                  acc.total_carbohydrate + cur.total_carbohydrate,
                saturated_fat: acc.saturated_fat + cur.saturated_fat,
              };
            } else
              return {
                meals: acc.meals,
                calories: acc.calories,
                cholesterol: acc.cholesterol,
                dietary_fiber: acc.dietary_fiber,
                protein: acc.protein,
                sugars: acc.sugars,
                sodium: acc.sodium,
                total_fat: acc.total_fat,
                total_carbohydrate: acc.total_carbohydrate,
                saturated_fat: acc.saturated_fat,
              };
          },
          {
            meals: 0,
            calories: 0,
            cholesterol: 0,
            dietary_fiber: 0,
            protein: 0,
            sugars: 0,
            sodium: 0,
            total_fat: 0,
            total_carbohydrate: 0,
            saturated_fat: 0,
          }
        );
        userDayTotals[day] = daysTotal;
      }
      return {
        ...state,
        userDayTotals,
      };
    case 'LOAD_CUSTOM_LABELS':
      customLabels = [];
      action.snapshot.forEach(doc => {
        customLabels.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      return {
        ...state,
        customLabels,
      };
    case 'SAVE_CUSTOM_DV':
      return {
        ...state,
        userSavedDv: {
          calories: action.payload.calories,
          total_fat: action.payload.total_fat,
          saturated_fat: action.payload.saturated_fat,
          cholesterol: action.payload.cholesterol,
          sodium: action.payload.sodium,
          total_carbohydrate: action.payload.total_carbohydrate,
          dietary_fiber: action.payload.dietary_fiber,
          sugars: action.payload.sugars,
          protein: action.payload.protein,
        },
      };
    case 'SAVE_CUSTOM_LABEL':
      customLabels = state.customLabels.slice();
      let newLabel = {
        ...action.data,
        id: action.id,
      };
      customLabels.unshift(newLabel);
      return {
        ...state,
        customLabels,
      };
    case 'SAVE_MEAL':
      userMeals = state.userMeals.slice();
      let newMeal = {
        ...action.data,
        id: action.id,
      };
      userMeals.unshift(newMeal);
      return {
        ...state,
        userMeals,
      };
    case 'DELETE_MEAL':
      userMeals = state.userMeals.slice();
      updatedMeals = userMeals.filter(meal => meal.id !== action.id);
      return {
        ...state,
        userMeals: updatedMeals,
      };
    case 'DELETE_CUSTOM_LABEL':
      customLabels = state.customLabels.slice();
      updatedLabels = customLabels.filter(label => label.id !== action.id);
      return {
        ...state,
        customLabels: updatedLabels,
      };
    case 'UPDATE_MEAL_NAME':
      userMeals = state.userMeals.slice();
      updatedMeals = userMeals.map(meal => {
        if (meal.id === action.id) meal.food_name = action.name;
        return meal;
      });
      return {
        ...state,
        userMeals: updatedMeals,
      };
    case 'UPDATE_CUSTOM_MEAL_NAME':
      customLabels = state.customLabels.slice();
      updatedLabels = customLabels.map(label => {
        if (label.id === action.id) label.food_name = action.name;
        return label;
      });
      return {
        ...state,
        customLabels: updatedLabels,
      };
    case 'UPDATE_SERVING':
      userMeals = state.userMeals.slice();
      updatedMeals = userMeals.map(meal => {
        if (meal.id === action.id) {
          meal = {
            ...meal,
            ...action.label,
          };
        }
        return meal;
      });
      return {
        ...state,
        userMeals: updatedMeals,
      };
    case 'UPDATE_TIMESTAMP':
      userMeals = state.userMeals.slice();
      updatedMeals = userMeals.map(meal => {
        if (meal.id === action.id) meal.createdAt = action.timestamp;
        return meal;
      });
      return {
        ...state,
        userMeals: updatedMeals,
      };
    case 'MERGELIST_ADD':
      mergeList = state.mergeList.slice();
      mergeList.push(action.id);
      return {
        ...state,
        mergeList,
      };
    case 'MERGELIST_REMOVE':
      mergeList = state.mergeList.slice();
      mergeList = mergeList.filter(item => item !== action.id);
      return {
        ...state,
        mergeList,
      };
    case 'MERGELIST_CLEAR':
      return {
        ...state,
        mergeList: [],
      };
    case 'SAVE_CUSTOM_DV_ERROR':
      console.error('SAVE CUSTOM DV ERROR: ', action.error);
      return state;
    case 'SAVE_MEAL_ERROR':
      console.error('SAVE MEAL ERROR: ', action.error);
      return state;
    case 'UPDATE_MEAL_NAME_ERROR':
      console.error('UPDATE_MEAL_NAME_ERROR: ', action.error);
      return state;
    case 'DELETE_MEAL_ERROR':
      console.error('DELETE MEAL ERROR: ', action.error);
      return state;
    case 'UPDATE_SERVING_ERROR':
      console.error('UPDATE SERVING ERROR: ', action.error);
      return state;
    case 'DELETE_CUSTOM_LABEL_ERROR':
      console.error('DELETE CUSTOM LABEL ERROR: ', action.error);
      return state;
    default:
      return state;
  }
};

import React, { useState } from 'react';
import { connect } from 'react-redux';
import CustomInput from './CustomInput';
import { resetLabel, simpleFields } from '../helpers';
import { saveMeal } from '../actions/nutritionActions';

const SimpleMeal = ({ saveMeal, backArrowOn, history }) => {
  const [custom, setCustom] = useState(resetLabel);
  const [mealName, setMealName] = useState(null);
  const [unit, setUnit] = useState(null);

  const handleCustom = e => {
    const val = e.target.value;
    const id = e.target.id;
    if (isFinite(val) && val >= 0) {
      setCustom(prevState => ({
        ...prevState,
        [id]: val,
      }));
    }
  };

  const handleUnit = e => {
    setUnit(e.target.value);
  };

  const handleName = e => {
    setMealName(e.target.value);
  };

  const handleSubmit = (e, i) => {
    e.preventDefault();
    const newLabel = {
      food_name: mealName,
      serving_qty: +custom.serving_qty || null,
      serving_unit: unit,
      weight_grams: +custom.weight_grams || null,
      calories: +custom.calories || 0,
      total_fat: +custom.total_fat || 0,
      saturated_fat: 0,
      cholesterol: 0,
      sodium: 0,
      total_carbohydrate: +custom.total_carbohydrate || 0,
      dietary_fiber: 0,
      sugars: 0,
      protein: +custom.protein || 0,
      type: 'simple',
    };

    if (mealName && ((unit && newLabel.serving_qty) || newLabel.weight_grams)) {
      setCustom(resetLabel);
      setMealName(null);
      setUnit(null);
      simpleFields.forEach(
        field => (document.getElementById(field).value = '')
      );
      saveMeal(newLabel);
      history.push('/');
    } else if (!mealName) {
      alert('Your meal needs a name!');
    } else alert('Your meal needs some serving info!');
  };

  backArrowOn();
  return (
    <div>
      <div className="container">
        <h5 className="center">Record a meal that's missing nutrition data</h5>
        <p className="center">
          Just enter the basics here so that you still have some record of it in
          your log!
        </p>
      </div>
      <div className="nutrition-display custom-label-display">
        <div className="nutrition-facts" id="custom-create-label">
          <table>
            <tbody>
              <tr className="no-underline">
                <th colSpan="5" className="nutrition-facts-title no-underline">
                  <div className="input-field textarea2-container">
                    <textarea
                      onChange={handleName}
                      required
                      id="textarea3"
                      className="materialize-textarea"></textarea>
                    <label style={{ color: '#9e9e9e' }} htmlFor="textarea3">
                      Meal Name (required)
                    </label>
                  </div>
                </th>
              </tr>
              <tr className="no-underline">
                <td className="sublabel no-underline">
                  Serving info: (required)
                </td>
                <td className="no-underline">
                  <CustomInput
                    field="serving_qty"
                    unit="qty"
                    handleCustom={handleCustom}
                  />
                </td>
                <td style={{ textAlign: 'center' }} className="no-underline">
                  <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                    <input
                      style={{ width: '5rem' }}
                      className="browser-default outline-input"
                      placeholder="plate/cup"
                      id="serving_unit"
                      type="text"
                      onChange={handleUnit}
                    />
                  </form>
                </td>
              </tr>
              <tr className="no-underline">
                <th colSpan="2" className="no-underline"></th>
                <td style={{ textAlign: 'center' }} className="no-underline">
                  or
                </td>
              </tr>
              <tr className="no-underline">
                <th colSpan="2" className="no-underline"></th>
                <td style={{ textAlign: 'center' }} className="no-underline">
                  <CustomInput
                    field="weight_grams"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr className="no-underline">
                <th colSpan="3" className="no-underline">
                  Nutrition Estimates (optional)
                </th>
              </tr>
              <tr className="no-underline">
                <td className="no-underline">Calories </td>
                <td className="no-underline">
                  <CustomInput
                    field="calories"
                    unit="cals"
                    handleCustom={handleCustom}
                  />
                </td>
                <td colSpan="2" className="no-underline"></td>
              </tr>
              <tr className="no-underline">
                <td className="no-underline">Fat</td>
                <td className="no-underline">
                  <CustomInput
                    field="total_fat"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
                <td colSpan="2" className="no-underline"></td>
              </tr>
              <tr className="no-underline">
                <td className="no-underline">Carbs</td>
                <td className="no-underline">
                  <CustomInput
                    field="total_carbohydrate"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
                <td colSpan="2" className="no-underline"></td>
              </tr>
              <tr className="no-underline">
                <td className="no-underline">Protein</td>
                <td className="no-underline">
                  <CustomInput
                    field="protein"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
                <td colSpan="2" className="no-underline"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="custom-label-button-container">
          <button
            className="button-blue"
            id="custom-label-add-button"
            onClick={handleSubmit}>
            Eat Now!
          </button>
        </div>
      </div>
    </div>
  );
};

const mapDispatch = dispatch => ({
  saveMeal: label => dispatch(saveMeal(label)),
  backArrowOn: () => dispatch({ type: 'BACK_ARROW_ON' }),
});

export default connect(null, mapDispatch)(SimpleMeal);

import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';

const ChartMacros = ({ total, userSavedDv }) => {
  // needed to reference the canvas element when the chart is created
  const chartRef = useRef(null);

  const maxValue = Math.max(
    Math.round((total.calories / userSavedDv.calories) * 100),
    Math.round((total.protein / userSavedDv.protein) * 100),
    Math.round((total.total_fat / userSavedDv.total_fat) * 100),
    Math.round(
      (total.total_carbohydrate / userSavedDv.total_carbohydrate) * 100
    )
  );

  useEffect(() => {
    const drawChart = () => {
      const config = {
        type: 'bar',
        data: {
          datasets: [
            {
              data: [
                `${Math.round((total.calories / userSavedDv.calories) * 100)}`,
                `${Math.round((total.protein / userSavedDv.protein) * 100)}`,
                `${Math.round(
                  (total.total_fat / userSavedDv.total_fat) * 100
                )}`,
                `${Math.round(
                  (total.total_carbohydrate / userSavedDv.total_carbohydrate) *
                    100
                )}`,
              ],
              backgroundColor: [
                'rgba(30,144,255,0.3)',
                'rgba(140,21,21,0.3)',
                'rgba(77,79,83,0.3)',
                'rgba(0,155,60,0.3)',
              ],
              barPercentage: 0.75,
            },
            {
              data: [100, 100, 100, 100],
              type: 'line',
              fill: false,
              radius: 0,
              backgroundColor: 'rgba(0,0,0,0.7)',
              borderColor: 'rgba(0,0,0,0.7)',
              borderCapStyle: 'round',
              borderWidth: 1,
              pointRadius: 0,
              pointHoverRadius: 0,
              tooltips: {
                enabled: false,
              },
              snapGaps: false,
            },
          ],
          labels: ['Calories', 'Protein', 'Fat', 'Carbs'],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  callback: value => value + '%',
                  beginAtZero: true,
                  // rounding up to the nearest 20 above the max value
                  max:
                    maxValue < 100
                      ? 100
                      : Math.ceil(maxValue / 10) % 2 === 0
                      ? Math.ceil(maxValue / 10) * 10
                      : Math.ceil(maxValue / 10) * 10 + 10,
                  autoSkipPadding: 20,
                },
                gridLines: {
                  display: true,
                },
                offset: true,
              },
            ],
            xAxes: [
              {
                gridLines: {
                  display: false,
                  // offsetGridLines: false,
                },
                ticks: {
                  padding: 5,
                },
              },
            ],
          },
          responsive: true,
          tooltips: {
            displayColors: false,
            callbacks: {
              label: (tooltipItem, data) => tooltipItem.yLabel + '% of goal',
            },
          },
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: 'Daily Goal Progress',
          },
          animation: {
            animateScale: true,
          },
        },
      };
      // unsure if getContext('2d') is necessary -- research
      new Chart(chartRef.current.getContext('2d'), config);
    };
    drawChart();
  }, [total, userSavedDv, maxValue]);

  return <canvas ref={chartRef} />;
};

export default ChartMacros;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import CustomInput from './CustomInput';
import { standards, resetLabel, fields } from '../helpers';
import { saveCustomDv } from '../actions/nutritionActions';
import { logOut } from '../actions/authActions';

const Account = ({ user, userSavedDv, saveCustomDv, logOut, history }) => {
  const [selectIndex, setSelectIndex] = useState(0);
  const [FDA, setFDA] = useState(standards[selectIndex]);
  const [custom, setCustom] = useState(resetLabel);

  const handleCustom = e => {
    const val = e.target.value;
    const id = e.target.id;
    if (isFinite(val) && val >= 0) {
      setCustom(prevState => ({
        ...prevState,
        [id]: val,
      }));
    }
  };

  const handleSelect = e => {
    setFDA(standards[e.target.value]);
    setSelectIndex(e.target.value);
  };

  const handleSubmit = e => {
    e.preventDefault();
    const updateObj = {
      calories: +(custom.calories || FDA.calories || userSavedDv.calories),
      total_fat: +(custom.total_fat || FDA.total_fat || userSavedDv.total_fat),
      saturated_fat: +(
        custom.saturated_fat ||
        FDA.saturated_fat ||
        userSavedDv.saturated_fat
      ),
      cholesterol: +(
        custom.cholesterol ||
        FDA.cholesterol ||
        userSavedDv.cholesterol
      ),
      sodium: +(custom.sodium || FDA.sodium || userSavedDv.sodium),
      total_carbohydrate: +(
        custom.total_carbohydrate ||
        FDA.total_carbohydrate ||
        userSavedDv.total_carbohydrate
      ),
      dietary_fiber: +(
        custom.dietary_fiber ||
        FDA.dietary_fiber ||
        userSavedDv.dietary_fiber
      ),
      sugars: +(custom.sugars || FDA.sugars || userSavedDv.sugars),
      protein: +(custom.protein || FDA.protein || userSavedDv.protein),
    };

    saveCustomDv(updateObj);
    setSelectIndex(0);
    setFDA(standards[0]);
    setCustom(resetLabel);
    fields.forEach(field => (document.getElementById(field).value = ''));
  };

  return (
    <div>
      <div className="container">
        <h6 className="center">
          {user.email && user.email.length > 30
            ? `${user.email.slice(0, 30)}...`
            : user.email}
        </h6>
        <div className="center input-field">
          <button
            className="button-white"
            id="log-out-button"
            type="submit"
            onClick={() => {
              logOut();
              history.push('/login');
            }}>
            Log Out
          </button>
        </div>
        <div className="center input-field">
          <button
            className="button-white"
            id="change-password-button"
            type="submit"
            onClick={() => {}}>
            Change Password
          </button>
        </div>
        <hr />
        <h5 className="center">Customize your Daily Values</h5>
        <p className="center">
          Select from FDA recommended guidelines (based on 1,600 to 3,200 daily
          calories) or customize fields individually
        </p>
      </div>
      <div className="nutrition-display custom-label-display">
        <div className="nutrition-facts" id="custom-dv-label">
          <table>
            <tbody>
              <tr className="no-underline">
                <th
                  colSpan="2"
                  className="nutrition-facts-title no-underline"></th>
                <th className="center no-underline">Saved Values</th>
                <td className="center grey-text no-underline">
                  FDA Guidelines
                </td>
                <td className="center grey-text no-underline">Custom Values</td>
              </tr>
              <tr className="thick-row">
                <th colSpan="2">Calories </th>
                <th className="calories">
                  {userSavedDv.calories.toLocaleString()}
                </th>
                <th>
                  <select
                    value={selectIndex}
                    id="standard-cals"
                    className="browser-default outline-select"
                    onChange={handleSelect}>
                    <option value="0">Select</option>
                    <option value="1">1,600</option>
                    <option value="2">2,000</option>
                    <option value="3">2,500</option>
                    <option value="4">3,200</option>
                  </select>
                </th>
                <td>
                  <CustomInput
                    field="calories"
                    unit="cals"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <th colSpan="2">Total Fat</th>
                <th>
                  {userSavedDv.total_fat.toLocaleString()}
                  <small>g</small>
                </th>
                <th>{FDA.total_fat.toLocaleString()}</th>
                <td>
                  <CustomInput
                    field="total_fat"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="sublabel">
                  Saturated Fat
                </td>
                <td>
                  {userSavedDv.saturated_fat.toLocaleString()}
                  <small>g</small>
                </td>
                <td>{FDA.saturated_fat.toLocaleString()}</td>
                <td>
                  <CustomInput
                    field="saturated_fat"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <th colSpan="2">Cholesterol</th>
                <th>
                  {userSavedDv.cholesterol.toLocaleString()}
                  <small>mg</small>
                </th>
                <th>{FDA.cholesterol.toLocaleString()}</th>
                <td>
                  <CustomInput
                    field="cholesterol"
                    unit="mg"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <th colSpan="2">Sodium </th>
                <th>
                  {userSavedDv.sodium.toLocaleString()}
                  <small>mg</small>
                </th>
                <th>{FDA.sodium.toLocaleString()}</th>
                <td>
                  <CustomInput
                    field="sodium"
                    unit="mg"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <th colSpan="2">Total Carbs</th>
                <th>
                  {userSavedDv.total_carbohydrate.toLocaleString()}
                  <small>g</small>
                </th>
                <th>{FDA.total_carbohydrate.toLocaleString()}</th>
                <td>
                  <CustomInput
                    field="total_carbohydrate"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="sublabel">
                  Dietary Fiber
                </td>
                <td>
                  {userSavedDv.dietary_fiber.toLocaleString()}
                  <small>g</small>
                </td>
                <td>{FDA.dietary_fiber.toLocaleString()}</td>
                <td>
                  <CustomInput
                    field="dietary_fiber"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2" className="sublabel">
                  Sugars
                </td>
                <td>
                  {userSavedDv.sugars.toLocaleString()}
                  <small>g</small>
                </td>
                <td>{FDA.sugars.toLocaleString()}</td>
                <td>
                  <CustomInput
                    field="sugars"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr className="thick-row">
                <th colSpan="2">Protein</th>
                <th>
                  {userSavedDv.protein.toLocaleString()}
                  <small>g</small>
                </th>
                <th>{FDA.protein.toLocaleString()}</th>
                <td>
                  <CustomInput
                    field="protein"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr className="no-underline">
                <td colSpan="5" className="no-underline"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="custom-dv-button-container">
          <button
            className="button-blue"
            id="custom-dv-button"
            onClick={handleSubmit}>
            Save
          </button>
        </div>
      </div>
      <div className="container">
        <hr />
      </div>
    </div>
  );
};

const mapState = state => ({
  user: state.auth.user,
  userSavedDv: state.nutrition.userSavedDv,
});

const mapDispatch = dispatch => ({
  saveCustomDv: obj => dispatch(saveCustomDv(obj)),
  logOut: () => dispatch(logOut()),
});

export default connect(mapState, mapDispatch)(Account);

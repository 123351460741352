import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams, Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import Modal from '../Modal';
import ConfirmDelete from '../ConfirmDelete';
import { dailyValues, roundSubFive } from '../../helpers';
import {
  saveMeal,
  deleteMeal,
  editMealName,
  editServing,
  editTimestamp,
} from '../../actions/nutritionActions';

const Meals = ({
  userMeals,
  customLabels,
  userSavedDv,
  saveMeal,
  deleteMeal,
  editMealName,
  editServing,
  editTimestamp,
  backArrowOn,
  history,
}) => {
  const [mealName, setMealName] = useState(null);
  const [date, setDate] = useState('');
  const [time, setTime] = useState('');
  const [userInput, setUserInput] = useState(null);
  const [selectIndex, setSelectIndex] = useState('0');
  const [customLabel, setCustomLabel] = useState(null);
  const [showModal, setShowModal] = useState(false);

  let { id } = useParams();
  let meal = userMeals.filter(meal => meal.id === id);
  let label = customLabels.filter(label => label.id === id);
  meal = meal[0] || label[0];

  useEffect(() => {
    const eventListenter = e => {
      if (e.keyCode === 27) {
        // ESCAPE
        setShowModal(false);
      }
    };
    document.addEventListener('keydown', eventListenter);
    return () => {
      document.removeEventListener('keydown', eventListenter);
    };
  });

  // This essentially serves as the No Match (404) route
  if (!meal) return <Redirect to="/" />;

  const resetCustom = () => {
    document.getElementById('edit-gram-input').value = '';
    document.getElementById('edit-unit-select').selectedIndex = '0';

    setCustomLabel(null);
    setUserInput(null);
    setSelectIndex('0');
  };

  const toggleModal = () => setShowModal(!showModal);

  const handleDuplicate = e => {
    e.preventDefault();
    saveMeal(meal);
    history.push('/');
  };
  const confirmDelete = e => {
    e.preventDefault();
    deleteMeal(id);
    history.push('/');
  };

  const handleNameChange = e => {
    setMealName(e.target.value);
  };

  const handleCustomChange = (e, num, index) => {
    let val = null;
    let i = index;
    if (e) {
      val = e.target.value;
      i = selectIndex;
      setUserInput(val);
    }
    // If Select is toggled to 'grams'
    if (i === '1') {
      const grams = val || num;
      const convert = grams / meal.weight_grams;
      setCustomLabel({
        weight_grams: +grams,
        serving_unit: meal.serving_unit || '',
        serving_qty:
          +roundSubFive((grams / meal.weight_grams) * meal.serving_qty) || '',
        calories: +roundSubFive(convert * meal.calories) || 0,
        total_fat: +roundSubFive(convert * meal.total_fat) || 0,
        saturated_fat: +roundSubFive(convert * meal.saturated_fat) || 0,
        cholesterol: +Math.round(convert * meal.cholesterol) || 0,
        sodium: +Math.round(convert * meal.sodium) || 0,
        total_carbohydrate:
          +roundSubFive(convert * meal.total_carbohydrate) || 0,
        dietary_fiber: +roundSubFive(convert * meal.dietary_fiber) || 0,
        sugars: +roundSubFive(convert * meal.sugars) || 0,
        protein: +roundSubFive(convert * meal.protein) || 0,
      });
    }
    // If Select is on 'servings' (default)
    if (i === '0') {
      const servings = val || num;
      setCustomLabel({
        weight_grams: servings * meal.weight_grams || '',
        serving_unit: meal.serving_unit || '',
        serving_qty: +roundSubFive(servings * meal.serving_qty) || '',
        calories: +roundSubFive(servings * meal.calories) || 0,
        total_fat: +roundSubFive(servings * meal.total_fat) || 0,
        saturated_fat: +roundSubFive(servings * meal.saturated_fat) || 0,
        cholesterol: +Math.round(servings * meal.cholesterol) || 0,
        sodium: +Math.round(servings * meal.sodium) || 0,
        total_carbohydrate:
          +roundSubFive(servings * meal.total_carbohydrate) || 0,
        dietary_fiber: +roundSubFive(servings * meal.dietary_fiber) || 0,
        sugars: +roundSubFive(servings * meal.sugars) || 0,
        protein: +roundSubFive(servings * meal.protein) || 0,
      });
    }
  };

  const handleSelect = e => {
    let val = e.target.value;
    setSelectIndex(val);
    handleCustomChange(null, userInput, val); // firing off customChange when select is toggled and input already contains a value. Complex because useState asynchronicity, so had to add two additional parameters to handleCustomChange and first test if the CustomChange is an input event or select event
  };

  const handleEditSubmit = e => {
    e.preventDefault();
    console.table({ customLabel, userInput, selectIndex });
    let newTimestamp;
    let dateArr = date.split('-');
    let timeArr = time.split(':');
    if (date && time) {
      newTimestamp = new Date(
        +dateArr[0],
        +dateArr[1] - 1,
        +dateArr[2],
        +timeArr[0],
        +timeArr[1]
      ).toJSON();
    } else if (date) {
      newTimestamp = new Date(
        +dateArr[0],
        +dateArr[1] - 1,
        +dateArr[2],
        +moment(meal.createdAt).format('H'),
        +moment(meal.createdAt).format('m')
      ).toJSON();
    } else if (time) {
      newTimestamp = new Date(
        +moment(meal.createdAt).format('YYYY'),
        +moment(meal.createdAt).format('M') - 1,
        +moment(meal.createdAt).format('D'),
        +timeArr[0],
        +timeArr[1]
      ).toJSON();
    }

    if (mealName) {
      editMealName(id, mealName);
      setMealName(null);
      let el = document.getElementById('textarea1');
      el.value = '';
    }

    if (newTimestamp !== undefined) {
      editTimestamp(id, newTimestamp);
      setDate('');
      setTime('');
      let dateEl = document.getElementById('date-edit');
      let timeEl = document.getElementById('time-edit');
      dateEl.value = '';
      timeEl.value = '';
    }

    if (customLabel && userInput) {
      editServing(id, customLabel);
      resetCustom();
    }
    history.push('/');
  };

  if (meal !== undefined) {
    backArrowOn();
    return (
      <div>
        {meal.photo &&
        meal.photo !==
          'https://d2eawub7utcl6.cloudfront.net/images/nix-apple-grey.png' ? (
          <div className="meal-thumb-cont">
            <img src={meal.photo} alt="food" />
          </div>
        ) : null}
        {meal.type === 'simple' ? (
          <div className="estimated-tag">ESTIMATES BY YOU</div>
        ) : null}
        <div className="meal-review-container nutrition-display">
          <div
            className="nutrition-facts"
            id={meal.type === 'simple' ? 'simple-facts' : null}>
            <div>
              <span className="nutrition-facts-title">{meal.food_name}</span>
            </div>
            <table>
              <tbody>
                <tr className="thick-row mini-header">
                  <td colSpan="5">
                    Serving:{' '}
                    {!meal.serving_unit
                      ? meal.weight_grams
                        ? Math.round(meal.weight_grams) + 'g'
                        : 'Unavailable'
                      : meal.weight_grams > 0
                      ? `${Math.round(meal.serving_qty * 100) / 100} ${
                          meal.serving_unit
                        } (${Math.round(meal.weight_grams)}g)`
                      : `${Math.round(meal.serving_qty * 100) / 100} ${
                          meal.serving_unit
                        }`}
                  </td>
                </tr>
                <tr className="mini-header">
                  <td colSpan="2">Amount</td>
                  <th colSpan="2"></th>
                  <th>% DV*</th>
                </tr>
                <tr className="thick-row">
                  <th colSpan="3">
                    Calories{' '}
                    <span className="calories">
                      {meal.calories.toLocaleString()}
                    </span>
                  </th>
                  <td className="calories"></td>
                  <td>{dailyValues(meal.calories, userSavedDv.calories)}</td>
                </tr>
                <tr>
                  <th colSpan="3">Total Fat {roundSubFive(meal.total_fat)}g</th>
                  <td></td>
                  <td>{dailyValues(meal.total_fat, userSavedDv.total_fat)}</td>
                </tr>
                {meal.type && meal.type === 'simple' ? null : (
                  <tr>
                    <td colSpan="3" className="sublabel">
                      Saturated Fat {roundSubFive(meal.saturated_fat)}g
                    </td>
                    <td></td>
                    <td>
                      {dailyValues(
                        meal.saturated_fat,
                        userSavedDv.saturated_fat
                      )}
                    </td>
                  </tr>
                )}
                {meal.type && meal.type === 'simple' ? null : (
                  <tr>
                    <th colSpan="3">
                      Cholesterol {Math.round(meal.cholesterol)}
                      mg
                    </th>
                    <td></td>
                    <td>
                      {dailyValues(meal.cholesterol, userSavedDv.cholesterol)}
                    </td>
                  </tr>
                )}
                {meal.type && meal.type === 'simple' ? null : (
                  <tr>
                    <th colSpan="3">
                      Sodium {Math.round(meal.sodium).toLocaleString()}
                      mg
                    </th>
                    <td></td>
                    <td>{dailyValues(meal.sodium, userSavedDv.sodium)}</td>
                  </tr>
                )}
                <tr>
                  <th colSpan="3">
                    Total Carbs {roundSubFive(meal.total_carbohydrate)}g
                  </th>
                  <td></td>
                  <td>
                    {dailyValues(
                      meal.total_carbohydrate,
                      userSavedDv.total_carbohydrate
                    )}
                  </td>
                </tr>
                {meal.type && meal.type === 'simple' ? null : (
                  <tr>
                    <td colSpan="3" className="sublabel">
                      Dietary Fiber {roundSubFive(meal.dietary_fiber)}g
                    </td>
                    <td></td>
                    <td>
                      {dailyValues(
                        meal.dietary_fiber,
                        userSavedDv.dietary_fiber
                      )}
                    </td>
                  </tr>
                )}
                {meal.type && meal.type === 'simple' ? null : (
                  <tr>
                    <td colSpan="3" className="sublabel">
                      Sugars {roundSubFive(meal.sugars)}g
                    </td>
                    <td></td>
                    <td>{dailyValues(meal.sugars, userSavedDv.sugars)}</td>
                  </tr>
                )}
                <tr className="thick-row">
                  <th colSpan="3">Protein {roundSubFive(meal.protein)}g</th>
                  <td></td>
                  <td>{dailyValues(meal.protein, userSavedDv.protein)}</td>
                </tr>
                <tr className="mini-header">
                  <td colSpan="5" className="no-underline">
                    * Daily Values based on a{' '}
                    {userSavedDv.calories.toLocaleString()} calorie diet.{' '}
                    {
                      <Link to="/account" className="sidenav-close">
                        Click here
                      </Link>
                    }{' '}
                    to customize values
                  </td>
                  <td className="no-underline"></td>
                </tr>
                {meal.ingredients ? (
                  <tr className="mini-header">
                    <td colSpan="5" className="no-underline">
                      {`Ingredients: ${meal.ingredients}`}
                    </td>
                  </tr>
                ) : null}
                <tr className="mini-header no-underline">
                  <td
                    colSpan="5"
                    className="no-underline"
                    style={{ textAlign: 'center' }}>
                    {`${moment(meal.createdAt).format('dddd')}, ${moment(
                      meal.createdAt
                    )
                      .format('l')
                      .replace('2019', '19')
                      .replace('2020', '20')} @ ${moment(meal.createdAt).format(
                      'LT'
                    )}`}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="meal-mod-container">
          <button
            className="button-blue"
            type="submit"
            onClick={handleDuplicate}>
            Eat Again!
          </button>

          <div className="edit-container">
            <h6>Modify Record</h6>
            <form onSubmit={handleEditSubmit} autoComplete="off">
              <div className="input-field textarea-container">
                <textarea
                  onChange={handleNameChange}
                  id="textarea1"
                  className="materialize-textarea"></textarea>
                <label style={{ color: '#9e9e9e' }} htmlFor="textarea1">
                  Meal Name
                </label>
              </div>
              <div className="edit-servings-container">
                <div className="browser-default">
                  <p>Quantity</p>
                  <input
                    className="edit-gram-input browser-default"
                    placeholder="custom"
                    id="edit-gram-input"
                    type="number"
                    inputMode="decimal"
                    step="0.01"
                    min="0"
                    onChange={handleCustomChange}
                  />
                </div>
                <div className="browser-default">
                  <p>Units</p>
                  <select
                    id="edit-unit-select"
                    className="browser-default edit-unit"
                    disabled={!meal.weight_grams}
                    onChange={handleSelect}>
                    <option value="0">servings</option>
                    <option value="1">grams</option>
                  </select>
                </div>
              </div>
              <div className="date-time-container">
                <div className="browser-default">
                  <p>Date</p>
                  <input
                    onChange={e => setDate(e.target.value)}
                    id="date-edit"
                    placeholder="date"
                    type="date"
                    className="browser-default outline-input"></input>
                </div>
                <div className="browser-default">
                  <p>Time</p>
                  <input
                    onChange={e => setTime(e.target.value)}
                    placeholder="time"
                    id="time-edit"
                    type="time"
                    className="browser-default outline-input"></input>
                </div>
              </div>
              <button className="button-white" id="edit-button" type="submit">
                Save Changes
              </button>
            </form>
          </div>
          <button
            className="button-white delete-button"
            type="submit"
            onClick={toggleModal}>
            Delete Record
          </button>
        </div>
        {showModal ? (
          <Modal>
            <ConfirmDelete
              toggleModal={toggleModal}
              confirmDelete={confirmDelete}
            />
          </Modal>
        ) : null}
      </div>
    );
  } else return null;
};

const mapState = state => ({
  userMeals: state.nutrition.userMeals,
  customLabels: state.nutrition.customLabels,
  userSavedDv: state.nutrition.userSavedDv,
});

const mapDispatch = dispatch => ({
  deleteMeal: id => dispatch(deleteMeal(id)),
  saveMeal: meal => dispatch(saveMeal(meal)),
  editMealName: (id, name) => dispatch(editMealName(id, name)),
  editServing: (id, label) => dispatch(editServing(id, label)),
  editTimestamp: (id, timestamp) => dispatch(editTimestamp(id, timestamp)),
  backArrowOn: () => dispatch({ type: 'BACK_ARROW_ON' }),
});

export default connect(mapState, mapDispatch)(Meals);

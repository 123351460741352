import React from 'react';

function Support() {
  return (
    <div className="center">
      <h3 className="center">SUPPORT</h3>
    </div>
  );
}

export default Support;

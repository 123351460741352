import * as firebase from 'firebase/app'; // just the core SDK
import 'firebase/firestore';
import 'firebase/auth';

// var firebaseConfig = {
//   apiKey: process.env.REACT_APP_FIREBASE_KEY,
//   authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
//   databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
//   projectId: 'caloriesdev',
//   storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
//   messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
//   appId: process.env.REACT_APP_FIREBASE_APP_ID,
//   measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
// };
var firebaseConfig = {
  apiKey: 'AIzaSyDSWGbrapkUzgsm28tw5h0zRHeFOkp0d70',
  authDomain: 'caloriesdev.firebaseapp.com',
  databaseURL: 'https://caloriesdev.firebaseio.com',
  projectId: 'caloriesdev',
  storageBucket: 'caloriesdev.appspot.com',
  messagingSenderId: '689540115345',
  appId: '1:689540115345:web:b7479ae4f94dfc32188830',
  measurementId: 'G-Y122M4L5ZR',
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const db = firebaseApp.firestore();

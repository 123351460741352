import React, { useState } from 'react';
import { connect } from 'react-redux';
import CustomInput from '../CustomInput';
import { resetLabel, customFields } from '../../helpers';
import { saveCustomLabel, saveMeal } from '../../actions/nutritionActions';

const CustomLabel = ({ saveCustomLabel, saveMeal, backArrowOn, history }) => {
  const [custom, setCustom] = useState(resetLabel);
  const [mealName, setMealName] = useState(null);
  const [unit, setUnit] = useState(null);

  const handleCustom = e => {
    const val = e.target.value;
    const id = e.target.id;
    if (isFinite(val) && val >= 0) {
      setCustom(prevState => ({
        ...prevState,
        [id]: val,
      }));
    }
  };

  const handleUnit = e => {
    setUnit(e.target.value);
  };

  const handleName = e => {
    setMealName(e.target.value);
  };

  const handleSubmit = (e, i) => {
    e.preventDefault();
    const newLabel = {
      food_name: mealName,
      serving_qty: +custom.serving_qty || null,
      serving_unit: unit,
      weight_grams: +custom.weight_grams || null,
      calories: +custom.calories || 0,
      total_fat: +custom.total_fat || 0,
      saturated_fat: +custom.saturated_fat || 0,
      cholesterol: +custom.cholesterol || 0,
      sodium: +custom.sodium || 0,
      total_carbohydrate: +custom.total_carbohydrate || 0,
      dietary_fiber: +custom.dietary_fiber || 0,
      sugars: +custom.sugars || 0,
      protein: +custom.protein || 0,
    };

    if (mealName && ((unit && newLabel.serving_qty) || newLabel.weight_grams)) {
      saveCustomLabel(newLabel);
      setCustom(resetLabel);
      setMealName(null);
      setUnit(null);
      customFields.forEach(
        field => (document.getElementById(field).value = '')
      );
      if (i === 0) {
        saveMeal(newLabel);
        history.push('/');
      } else history.push('/custom');
    } else if (!mealName) {
      alert('Your label needs a name!');
    } else alert('Your label needs serving info!');
  };

  backArrowOn();
  return (
    <div>
      <div className="container">
        <h5 className="center">Create a Custom Label</h5>
      </div>
      <div className="nutrition-display custom-label-display">
        <div className="nutrition-facts" id="custom-create-label">
          <table>
            <tbody>
              <tr className="no-underline">
                <th colSpan="5" className="nutrition-facts-title no-underline">
                  <div className="input-field textarea2-container">
                    <textarea
                      onChange={handleName}
                      required
                      id="textarea2"
                      className="materialize-textarea"></textarea>
                    <label style={{ color: '#9e9e9e' }} htmlFor="textarea2">
                      Food/Ingredient Name
                    </label>
                  </div>
                </th>
              </tr>
              <tr className="no-underline">
                <td className="sublabel no-underline">Serving size:</td>
                <td className="no-underline">
                  <CustomInput
                    field="serving_qty"
                    unit="qty"
                    handleCustom={handleCustom}
                  />
                </td>
                <td className="no-underline">
                  <form autoComplete="off" onSubmit={e => e.preventDefault()}>
                    <input
                      style={{ width: '5rem' }}
                      className="browser-default outline-input"
                      placeholder="oz/tsp/cups"
                      id="serving_unit"
                      type="text"
                      onChange={handleUnit}
                    />
                  </form>
                </td>
                <td className="no-underline">
                  <CustomInput
                    field="weight_grams"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr className="thick-row">
                <th colSpan="3">Calories </th>
                <td>
                  <CustomInput
                    field="calories"
                    unit="cals"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <th colSpan="3">Total Fat</th>
                <td>
                  <CustomInput
                    field="total_fat"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="sublabel">
                  Saturated Fat
                </td>
                <td>
                  <CustomInput
                    field="saturated_fat"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <th colSpan="3">Cholesterol</th>
                <td>
                  <CustomInput
                    field="cholesterol"
                    unit="mg"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <th colSpan="3">Sodium </th>
                <td>
                  <CustomInput
                    field="sodium"
                    unit="mg"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <th colSpan="3">Total Carbs</th>
                <td>
                  <CustomInput
                    field="total_carbohydrate"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="sublabel">
                  Dietary Fiber
                </td>
                <td>
                  <CustomInput
                    field="dietary_fiber"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="3" className="sublabel">
                  Sugars
                </td>
                <td>
                  <CustomInput
                    field="sugars"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr className="thick-row">
                <th colSpan="3">Protein</th>
                <td>
                  <CustomInput
                    field="protein"
                    unit="grams"
                    handleCustom={handleCustom}
                  />
                </td>
              </tr>
              <tr className="no-underline">
                <td colSpan="5" className="no-underline"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div id="custom-label-button-container">
          <button
            className="button-blue"
            id="custom-label-add-button"
            onClick={e => handleSubmit(e, 0)}>
            Eat Now!
          </button>
          <button
            className="button-white"
            id="custom-label-save-button"
            onClick={handleSubmit}>
            Save for Later
          </button>
        </div>
      </div>
      <div className="container">
        <hr />
      </div>
    </div>
  );
};

const mapDispatch = dispatch => ({
  saveCustomLabel: label => dispatch(saveCustomLabel(label)),
  saveMeal: label => dispatch(saveMeal(label)),
  backArrowOn: () => dispatch({ type: 'BACK_ARROW_ON' }),
});

export default connect(null, mapDispatch)(CustomLabel);

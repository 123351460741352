import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import moment from 'moment';
import Spinner from '../Spinner';
import MealCard from './MealCard';
import Modal from '../Modal';
import { saveMergedMeal, deleteMeal } from '../../actions/nutritionActions';
import { emojiArr } from '../../helpers';
import ConfirmDelete from '../ConfirmDelete';

const Log = ({
  isAuthenticated,
  isVerifying,
  mealsLoaded,
  userMeals,
  userSavedDv,
  mergeList,
  saveMergedMeal,
  deleteMeal,
  mergeAdd,
  mergeRemove,
  mergeClear,
  backArrowOff,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [arrowHidden, setArrowHidden] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => setArrowHidden(true), 10000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    const eventListenter = e => {
      const modalActive = document.getElementById('modal-delete');
      if (e.keyCode === 27) {
        // ESCAPE
        if (modalActive) setShowModal(false);
        clearCheckboxes();
      }
    };
    document.addEventListener('keydown', eventListenter);
    return () => {
      document.removeEventListener('keydown', eventListenter);
    };
  });

  useEffect(() => {
    backArrowOff();
    if (mergeList.length > 0) mergeClear();
  }, []); // eslint-disable-line

  if (isAuthenticated === false && isVerifying === false)
    return <Redirect to="/login" />;

  const toggleModal = () => setShowModal(!showModal);

  const clearCheckboxes = () => {
    mergeClear();
    let checks = document.querySelectorAll('input:checked');
    checks.forEach(box => (box.checked = false));
  };

  const handleCheck = e => {
    if (e.target.checked) {
      mergeAdd(e.target.value);
    } else {
      mergeRemove(e.target.value);
    }
  };

  const handleMerge = e => {
    e.preventDefault();
    let maxChar = Math.round(60 / mergeList.length);
    let selectedMeals = userMeals.filter(meal => mergeList.includes(meal.id));
    let mergedMeal = selectedMeals.reduce(
      (acc, cur) => ({
        createdAt: cur.createdAt,
        food_name: acc.food_name + cur.food_name.slice(0, maxChar) + ' + ',
        ingredients:
          acc.ingredients +
          cur.food_name +
          ' (' +
          cur.weight_grams +
          ' grams), ',
        numIngredients: acc.numIngredients + 1,
        serving_unit: acc.serving_unit + cur.serving_unit,
        serving_qty: acc.serving_qty + cur.serving_qty,
        weight_grams: acc.weight_grams + cur.weight_grams,
        calories: acc.calories + cur.calories,
        cholesterol: acc.cholesterol + cur.cholesterol,
        dietary_fiber: acc.dietary_fiber + cur.dietary_fiber,
        protein: acc.protein + cur.protein,
        sugars: acc.sugars + cur.sugars,
        sodium: acc.sodium + cur.sodium,
        total_fat: acc.total_fat + cur.total_fat,
        saturated_fat: acc.saturated_fat + cur.saturated_fat,
        total_carbohydrate: acc.total_carbohydrate + cur.total_carbohydrate,
      }),
      {
        createdAt: '',
        food_name: '',
        ingredients: '',
        numIngredients: 0,
        serving_unit: '',
        serving_qty: 0,
        weight_grams: 0,
        calories: 0,
        cholesterol: 0,
        dietary_fiber: 0,
        protein: 0,
        sugars: 0,
        sodium: 0,
        total_fat: 0,
        total_carbohydrate: 0,
        saturated_fat: 0,
      }
    );

    // check if merged ingredients all share common serving unit--- set serving_unit to false if not
    let chars = Math.round(
      mergedMeal.serving_unit.length / mergedMeal.numIngredients
    );
    let commonUnit = '';
    for (let i = 0; i < mergedMeal.numIngredients; i++) {
      let unit = mergedMeal.serving_unit.slice(i * chars, i * chars + chars);
      if (commonUnit.length === 0) commonUnit = unit;
      else {
        if (commonUnit !== unit) commonUnit = false;
      }
    }
    mergedMeal.serving_unit = commonUnit;
    if (commonUnit === false) mergedMeal.serving_qty = false;

    // trim the ' + ' from the last name
    mergedMeal.food_name = mergedMeal.food_name.substring(
      0,
      mergedMeal.food_name.length - 3
    );
    // trim the ', ' from the last ingredient
    mergedMeal.ingredients = mergedMeal.ingredients.substring(
      0,
      mergedMeal.ingredients.length - 2
    );
    saveMergedMeal(mergedMeal);
    mergeList.forEach(id => deleteMeal(id));
    clearCheckboxes();
  };

  const handleBatchedDelete = e => {
    e.preventDefault();
    toggleModal();
  };

  const confirmDelete = e => {
    e.preventDefault();
    mergeList.forEach(id => deleteMeal(id));
    clearCheckboxes();
    toggleModal();
  };

  const handleClear = e => {
    e.preventDefault();
    clearCheckboxes();
  };

  let today = new Date().toLocaleDateString();
  let yesterday = new Date(
    new Date().getTime() - 24 * 60 * 60 * 1000
  ).toLocaleDateString();

  let dayTotals = {};
  for (let i = 0; i < userMeals.length; i++) {
    let formatted = moment(userMeals[i].createdAt).format('l');
    if (!dayTotals[formatted]) dayTotals[formatted] = {};
  }

  for (const day in dayTotals) {
    let daysTotal = userMeals.reduce(
      (acc, cur) => {
        if (moment(cur.createdAt).format('l') === day) {
          return {
            meals: acc.meals + 1,
            calories: acc.calories + cur.calories,
            cholesterol: acc.cholesterol + cur.cholesterol,
            dietary_fiber: acc.dietary_fiber + cur.dietary_fiber,
            protein: acc.protein + cur.protein,
            sugars: acc.sugars + cur.sugars,
            sodium: acc.sodium + cur.sodium,
            total_fat: acc.total_fat + cur.total_fat,
            total_carbohydrate: acc.total_carbohydrate + cur.total_carbohydrate,
            saturated_fat: acc.saturated_fat + cur.saturated_fat,
          };
        } else
          return {
            meals: acc.meals,
            calories: acc.calories,
            cholesterol: acc.cholesterol,
            dietary_fiber: acc.dietary_fiber,
            protein: acc.protein,
            sugars: acc.sugars,
            sodium: acc.sodium,
            total_fat: acc.total_fat,
            total_carbohydrate: acc.total_carbohydrate,
            saturated_fat: acc.saturated_fat,
          };
      },
      {
        meals: 0,
        calories: 0,
        cholesterol: 0,
        dietary_fiber: 0,
        protein: 0,
        sugars: 0,
        sodium: 0,
        total_fat: 0,
        total_carbohydrate: 0,
        saturated_fat: 0,
      }
    );
    dayTotals[day] = daysTotal;
  }

  let day = '';
  let sortedMeals = userMeals.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  // In order for sticky day header to push, rather than overlap, will need to refactor this such that each day (header + all meal cards) are contained in their own day div. Consider populating an array with a loop through sortedMeals

  let mealList = sortedMeals.map(meal => {
    if (day === moment(meal.createdAt).format('l')) {
      return (
        <MealCard
          meal={meal}
          handleCheck={handleCheck}
          key={`${meal.id}-card`}
        />
      );
    } else {
      day = moment(meal.createdAt).format('l');
      if (Object.keys(dayTotals).length > 0) {
        return (
          <Fragment key={meal.id}>
            <Link
              className="day-header"
              to={{
                pathname: `/day/${day}`,
                state: {
                  total: dayTotals[day],
                  date: day,
                  day: moment(meal.createdAt).format('dddd'),
                  userSavedDv: userSavedDv,
                },
              }}>
              <div style={{ color: '#2b2b2b' }}>
                {day === today
                  ? `Today ${day
                      .replace('2019', '19')
                      .replace('2020', '20')
                      .replace('2021', '21')}`
                  : day === yesterday
                  ? `Yesterday ${day
                      .replace('2019', '19')
                      .replace('2020', '20')
                      .replace('2021', '21')}`
                  : `${moment(meal.createdAt).format('ddd')} ${day
                      .replace('2019', '19')
                      .replace('2020', '20')
                      .replace('2021', '21')}`}
              </div>
              <div>
                Calories: {Math.round(dayTotals[day].calories).toLocaleString()}
              </div>
              <div>Protein: {Math.round(dayTotals[day].protein)}g</div>
              <div>Fat: {Math.round(dayTotals[day].total_fat)}g</div>
              <div>Carbs: {Math.round(dayTotals[day].total_carbohydrate)}g</div>
            </Link>
            <MealCard
              meal={meal}
              handleCheck={handleCheck}
              key={`${meal.id}-card`}
            />
          </Fragment>
        );
      } else return null;
    }
  });

  return (
    <div>
      <div className="nav-button-backer"></div>
      {mergeList.length === 1 ? (
        <div className="merge-container">
          <button
            className="button-white"
            id="batch-delete-button"
            type="submit"
            onClick={handleBatchedDelete}>
            Delete {`(${mergeList.length})`}
          </button>
          <button
            className="button-white"
            id="clear-button"
            type="submit"
            onClick={handleClear}>
            Cancel
          </button>
        </div>
      ) : mergeList.length > 1 ? (
        <div className="merge-container">
          <button
            className="button-white"
            id="batch-delete-button"
            type="submit"
            onClick={handleBatchedDelete}>
            Delete {`(${mergeList.length})`}
          </button>
          <button
            className="button-blue"
            id="merge-button"
            type="submit"
            onClick={handleMerge}>
            Merge {`(${mergeList.length})`}
          </button>
          <button
            className="button-white"
            id="clear-button"
            type="submit"
            onClick={handleClear}>
            Cancel
          </button>
        </div>
      ) : null}
      {mealList.length > 0 ? (
        <div>
          <div className="meal-container">{mealList}</div>
          <div className="center">¯\_(ツ)_/¯ </div>
        </div>
      ) : mealsLoaded === true ? (
        <div className="container">
          <div className="animated-arrow-container">
            <i
              className="material-icons animated-arrow"
              aria-hidden="true"
              hidden={arrowHidden}>
              arrow_upward
            </i>
          </div>

          <Link to="/" className="empty-log">
            <p>
              Your meal log is <b>empty :(</b>
            </p>
            <br />
            <p>
              Search from over 500,000 nutrition labels to{' '}
              <b>add your first meal!</b>
            </p>
            <br />
            <p>
              ༼∩☉_☉༽⊃━☆ﾟ. * ･ ｡ﾟ
              <span style={{ fontSize: '2.5rem' }}>
                {emojiArr[Math.floor(Math.random() * emojiArr.length)]}
              </span>
            </p>
          </Link>
        </div>
      ) : (
        <Spinner />
      )}
      {showModal ? (
        <Modal>
          <ConfirmDelete
            toggleModal={toggleModal}
            confirmDelete={confirmDelete}
          />
        </Modal>
      ) : null}
    </div>
  );
};

const mapState = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  isVerifying: state.auth.isVerifying,
  userMeals: state.nutrition.userMeals,
  mealsLoaded: state.nutrition.mealsLoaded,
  mergeList: state.nutrition.mergeList,
  userSavedDv: state.nutrition.userSavedDv,
});

const mapDispatch = dispatch => ({
  saveMergedMeal: meal => dispatch(saveMergedMeal(meal)),
  deleteMeal: id => dispatch(deleteMeal(id)),
  mergeAdd: id => {
    dispatch({
      type: 'MERGELIST_ADD',
      id,
    });
  },
  mergeRemove: id => {
    dispatch({
      type: 'MERGELIST_REMOVE',
      id,
    });
  },
  mergeClear: () => {
    dispatch({ type: 'MERGELIST_CLEAR' });
  },
  backArrowOff: () => dispatch({ type: 'BACK_ARROW_OFF' }),
});

export default connect(mapState, mapDispatch)(Log);

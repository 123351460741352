import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { properCase } from '../../helpers';

function Autocomplete({
  data,
  getItem,
  resetForm,
  group,
  foodId,
  foodName,
  searchTab,
  searchTabRight,
  searchTabLeft,
}) {
  // if one of the two tabs is empty, switch the the tab with results
  useEffect(() => {
    if (data && data[group] && data[group].length === 0) {
      switch (group) {
        case 'common':
          searchTabRight();
          break;
        case 'branded':
          searchTabLeft();
          break;
        default:
          break;
      }
    }
  }, [data]); // eslint-disable-line

  useEffect(() => {
    const eventListener = e => {
      let el = document.getElementById('query-list');
      switch (e.keyCode) {
        case 27: // ESCAPE
          if (!document.getElementById('label-close')) resetForm();
          break;
        case 13: // ENTER
          if (document.activeElement.parentElement.id === 'query-list')
            document.activeElement.click();
          break;
        case 38: // UP
          if (el) {
            if (
              document.activeElement === el.firstChild ||
              document.activeElement.parentElement.id !== 'query-list'
            ) {
              break;
            } else if (document.activeElement.previousSibling) {
              document.activeElement.previousSibling.focus();
            }
          }
          break;
        case 40: // DOWN
          if (el) {
            if (document.activeElement.parentElement.id !== 'query-list') {
              el.firstChild.focus();
            } else if (document.activeElement.nextSibling) {
              document.activeElement.nextSibling.focus();
            }
          }
          break;
        case 39: // RIGHT
          switch (searchTab) {
            case 'right':
              searchTabLeft();
              break;
            case 'left':
              searchTabRight();
              break;
            default:
              break;
          }
          break;
        case 37: // LEFT
          switch (searchTab) {
            case 'right':
              searchTabLeft();
              break;
            case 'left':
              searchTabRight();
              break;
            default:
              break;
          }
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', eventListener);
    return () => {
      document.removeEventListener('keydown', eventListener);
    };
  });

  if (data && data[group] && data[group].length > 0) {
    const optionsList = data[group].map(food => (
      <li
        id={food[foodId]}
        tabIndex="0"
        key={food[foodId]}
        onClick={getItem}
        className="query-results">
        {properCase(food[foodName])}
      </li>
    ));
    return (
      <div>
        <ul id="query-list" className="query-results">
          {optionsList}
        </ul>
      </div>
    );
  }
  return (
    <div>
      <ul id="query-list" className="query-results">
        <li className="center" style={{ cursor: 'default' }}>
          {group === 'common'
            ? "No 'Common' results - Check 'Branded' tab!"
            : "No 'Branded' results - Check 'Common' tab!"}
        </li>
      </ul>
    </div>
  );
}

const mapState = state => ({
  searchTab: state.nav.searchTab,
});

const mapDispatch = dispatch => ({
  searchTabRight: () => dispatch({ type: 'SEARCH_TAB_RIGHT' }),
  searchTabLeft: () => dispatch({ type: 'SEARCH_TAB_LEFT' }),
});

export default connect(mapState, mapDispatch)(Autocomplete);

import React from 'react';
import { connect } from 'react-redux';

const TabButtons = ({ active, searchTabRight, searchTabLeft }) => {
  return (
    <div>
      <div id="tab-button-toggle">
        <button
          onClick={searchTabLeft}
          id="tab-toggle-left"
          className={
            active === 'left' ? 'tab-toggle-active' : 'tab-toggle-inactive'
          }>
          Common
        </button>
        <button
          onClick={searchTabRight}
          id="tab-toggle-right"
          className={
            active === 'right' ? 'tab-toggle-active' : 'tab-toggle-inactive'
          }>
          Branded
        </button>
      </div>
    </div>
  );
};

const mapDispatch = dispatch => ({
  searchTabRight: () => dispatch({ type: 'SEARCH_TAB_RIGHT' }),
  searchTabLeft: () => dispatch({ type: 'SEARCH_TAB_LEFT' }),
});

export default connect(null, mapDispatch)(TabButtons);

import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

const Navbar = ({ backArrow, history }) => {
  return (
    <div>
      <div className="navbar-fixed">
        <nav className="z-depth-0">
          <div className="nav-wrapper container">
            {backArrow ? (
              <i
                className="back-arrow material-icons"
                onClick={() => history.goBack()}>
                arrow_back_ios
              </i>
            ) : (
              <span>
                <i
                  className="material-icons sidenav-trigger "
                  data-target="side-menu">
                  menu
                </i>
              </span>
            )}

            <Link className="brand-logo" to="/">
              cal v0.27
            </Link>
            {/* add button */}
            <div className="right">
              <span>
                <i
                  onClick={() => {
                    const el = document.getElementById('search');
                    el.value = '';
                  }}
                  className="material-icons sidenav-trigger"
                  data-target="side-form">
                  add
                </i>
              </span>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
const mapState = state => ({
  backArrow: state.nav.backArrow,
});

const NavbarWithRouter = withRouter(connect(mapState)(Navbar));

export default NavbarWithRouter;

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { dailyValues, roundSubFive } from '../helpers';
import ChartMacros from './ChartMacros';
import ChartPercent from './ChartPercent';

const Meals = ({ backArrowOn, location }) => {
  const { total } = location.state;
  const { userSavedDv } = location.state; // passing DV through router so that, upon refresh of the DayDetail page, percentages don't momentarily flash back to reflect initialState of DV in the reducer

  if (total !== undefined) {
    backArrowOn();
    return (
      <div className="container">
        <div className="container">
          <div className="center estimated-tag">
            {location.state.day} - {location.state.date}
          </div>
        </div>
        <div className="daychart-container">
          <ChartPercent total={total} userSavedDv={userSavedDv} />
        </div>
        <div className="daychart-container">
          <ChartMacros total={total} />
        </div>
        <div className="meal-review-container nutrition-display">
          <div className="nutrition-facts">
            <div>
              <span className="nutrition-facts-title">Day Total</span>
            </div>
            <table>
              <tbody>
                <tr className="mini-header no-underline">
                  <th colSpan="4" className="no-underline"></th>
                  <th className="no-underline">% DV*</th>
                </tr>
                <tr className="thick-row">
                  <th colSpan="3">
                    Calories{' '}
                    <span className="calories">
                      {total.calories.toLocaleString()}
                    </span>
                  </th>
                  <td className="calories"></td>
                  <td>{dailyValues(total.calories, userSavedDv.calories)}</td>
                </tr>
                <tr>
                  <th colSpan="3">
                    Total Fat {roundSubFive(total.total_fat)}g
                  </th>
                  <td></td>
                  <td>{dailyValues(total.total_fat, userSavedDv.total_fat)}</td>
                </tr>
                <tr>
                  <td colSpan="3" className="sublabel">
                    Saturated Fat {roundSubFive(total.saturated_fat)}g
                  </td>
                  <td></td>
                  <td>
                    {dailyValues(
                      total.saturated_fat,
                      userSavedDv.saturated_fat
                    )}
                  </td>
                </tr>
                <tr>
                  <th colSpan="3">
                    Cholesterol {Math.round(total.cholesterol)}
                    mg
                  </th>
                  <td></td>
                  <td>
                    {dailyValues(total.cholesterol, userSavedDv.cholesterol)}
                  </td>
                </tr>
                <tr>
                  <th colSpan="3">
                    Sodium {Math.round(total.sodium).toLocaleString()}
                    mg
                  </th>
                  <td></td>
                  <td>{dailyValues(total.sodium, userSavedDv.sodium)}</td>
                </tr>
                <tr>
                  <th colSpan="3">
                    Total Carbs {roundSubFive(total.total_carbohydrate)}g
                  </th>
                  <td></td>
                  <td>
                    {dailyValues(
                      total.total_carbohydrate,
                      userSavedDv.total_carbohydrate
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" className="sublabel">
                    Dietary Fiber {roundSubFive(total.dietary_fiber)}g
                  </td>
                  <td></td>
                  <td>
                    {dailyValues(
                      total.dietary_fiber,
                      userSavedDv.dietary_fiber
                    )}
                  </td>
                </tr>
                <tr>
                  <td colSpan="3" className="sublabel">
                    Sugars {roundSubFive(total.sugars)}g
                  </td>
                  <td></td>
                  <td>{dailyValues(total.sugars, userSavedDv.sugars)}</td>
                </tr>
                <tr className="thick-row">
                  <th colSpan="3">Protein {roundSubFive(total.protein)}g</th>
                  <td></td>
                  <td>{dailyValues(total.protein, userSavedDv.protein)}</td>
                </tr>
                <tr className="mini-header no-underline">
                  <td colSpan="5" className="no-underline">
                    * Daily Values based on a{' '}
                    {userSavedDv.calories.toLocaleString()} calorie diet.{' '}
                    {
                      <Link to="/account" className="sidenav-close">
                        Click here
                      </Link>
                    }{' '}
                    to customize values
                  </td>
                  <td className="no-underline"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  } else return null;
};

const mapState = state => ({
  userMeals: state.nutrition.userMeals,
  userSavedDv: state.nutrition.userSavedDv,
});

const mapDispatch = dispatch => ({
  backArrowOn: () => dispatch({ type: 'BACK_ARROW_ON' }),
});

export default connect(mapState, mapDispatch)(Meals);

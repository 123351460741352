export const properCase = string => {
  if (string[0] === string[0].toLowerCase()) {
    let resultArr = [];
    let splitArr = string.split(' ');
    for (let i = 0; i < splitArr.length; i++) {
      let properWord = splitArr[i][0].toUpperCase();
      for (let j = 1; j < splitArr[i].length; j++) {
        properWord += splitArr[i][j];
      }
      resultArr.push(properWord);
    }
    return resultArr.join(' ');
  }
  return string;
};

export const roundSubFive = number => {
  if (number >= 0.05 && number < 5) return number.toFixed(1);
  else return Math.round(number);
};

export const dailyValues = (product, user, conversion) => {
  // if (conversion > 0 && conversion < 1000000)
  // isFinite is like isNumber, except it rules out NaN and Infinity
  if (isFinite(conversion))
    return `${Math.round(((conversion * product) / user) * 100)}%`;
  else return `${Math.round((product / user) * 100)}%`;
};

export const resetLabel = {
  calories: '',
  total_fat: '',
  saturated_fat: '',
  cholesterol: '',
  sodium: '',
  total_carbohydrate: '',
  dietary_fiber: '',
  sugars: '',
  protein: '',
};

export const standards = [
  resetLabel,
  {
    calories: 1600,
    total_fat: 52,
    saturated_fat: 13,
    cholesterol: 300,
    sodium: 2400,
    total_carbohydrate: 240,
    dietary_fiber: 16,
    sugars: '',
    protein: 40,
  },
  {
    calories: 2000,
    total_fat: 65,
    saturated_fat: 20,
    cholesterol: 300,
    sodium: 2400,
    total_carbohydrate: 300,
    dietary_fiber: 25,
    sugars: '',
    protein: 50,
  },
  {
    calories: 2500,
    total_fat: 80,
    saturated_fat: 25,
    cholesterol: 300,
    sodium: 2400,
    total_carbohydrate: 420,
    dietary_fiber: 35,
    sugars: '',
    protein: 63,
  },
  {
    calories: 3200,
    total_fat: 104,
    saturated_fat: 32,
    cholesterol: 300,
    sodium: 2400,
    total_carbohydrate: 480,
    dietary_fiber: 40,
    sugars: '',
    protein: 80,
  },
];

export const fields = [
  'calories',
  'total_fat',
  'saturated_fat',
  'cholesterol',
  'sodium',
  'total_carbohydrate',
  'dietary_fiber',
  'sugars',
  'protein',
];

export const customFields = [
  'calories',
  'total_fat',
  'saturated_fat',
  'cholesterol',
  'sodium',
  'total_carbohydrate',
  'dietary_fiber',
  'sugars',
  'protein',
  'serving_qty',
  'serving_unit',
  'weight_grams',
  'textarea2',
];

export const simpleFields = [
  'calories',
  'total_fat',
  'total_carbohydrate',
  'protein',
  'serving_qty',
  'serving_unit',
  'weight_grams',
  'textarea3',
];

export const emojiArr = [
  '🍌',
  '🥜',
  '🍝',
  '🥑',
  '🍎',
  '🍊',
  '🍍',
  '🥕',
  '🥖',
  '🍟',
  '🍩',
  '🍦',
  '🍚',
  '🍪',
  '🍳',
  '🧀',
  '🍕',
];

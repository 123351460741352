import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { signupUser, loginUser } from '../actions/authActions';

const LogIn = ({
  signupUser,
  loginUser,
  authError,
  isAuthenticated,
  isLoggingOut,
}) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = e => {
    setPassword(e.target.value);
  };

  const handleLogIn = e => {
    e.preventDefault();
    loginUser(email, password);
  };

  const handleSignUp = e => {
    e.preventDefault();
    signupUser(email, password);
  };

  if (isAuthenticated && !isLoggingOut) return <Redirect to="/" />;
  return (
    <div>
      <div className="login-container">
        <form onSubmit={handleLogIn}>
          <div className="input-field">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              onChange={handleEmailChange}
            />
          </div>
          <div className="input-field">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              id="password"
              onChange={handlePasswordChange}
            />
          </div>
          <div className="input-field ">
            <button className="button-blue " id="login-button" type="submit">
              Log In
            </button>
          </div>
        </form>
        <div className="input-field">
          <button
            className="button-white"
            id="sign-up-button"
            type="submit"
            onClick={handleSignUp}>
            Sign Up
          </button>
        </div>
      </div>
      <div id="login-error-message">
        {authError ? <p>{authError}</p> : null}
      </div>
    </div>
  );
};

const mapState = state => ({
  authError: state.auth.authError,
  isAuthenticated: state.auth.isAuthenticated,
  isLoggingOut: state.auth.isLoggingOut,
});

const mapDispatch = dispatch => ({
  loginUser: (email, password) => dispatch(loginUser(email, password)),
  signupUser: (email, password) => dispatch(signupUser(email, password)),
});

export default connect(
  mapState,
  mapDispatch
)(LogIn);

const initialState = {
  backArrow: false,
  searchTab: 'left',
  fetchingData: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'BACK_ARROW_ON':
      return {
        ...state,
        backArrow: true,
      };
    case 'BACK_ARROW_OFF':
      return {
        ...state,
        backArrow: false,
      };
    case 'SEARCH_TAB_RIGHT':
      return {
        ...state,
        searchTab: 'right',
      };
    case 'SEARCH_TAB_LEFT':
      return {
        ...state,
        searchTab: 'left',
      };
    case 'FETCH_BEGIN':
      return {
        ...state,
        fetchingData: true,
      };
    case 'FETCH_END':
      return {
        ...state,
        fetchingData: false,
      };
    default:
      return state;
  }
};

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Autocomplete from '../search/Autocomplete';
import NutritionLabel from '../search/NutritionLabel';
import TabButtons from '../layout/TabButtons';

const SideForm = ({
  isAuthenticated,
  searchTab,
  searchTabLeft,
  backArrow,
  fetchingData,
  fetchBegin,
  fetchEnd,
}) => {
  let timeout = null; // timeout in search field so API not pinged when user still typing
  const DELAY = 250; // for search API timeout
  const MAX_COMMON = 15,
    MAX_BRANDED = 15;
  const initData = {
    common: [],
    branded: [],
  };
  const headers = {
    'x-app-id': process.env.REACT_APP_x_app_id,
    'x-app-key': process.env.REACT_APP_x_app_key,
    'x-remote-user-id': 0,
  };
  const instantUrl =
    'https://trackapi.nutritionix.com/v2/search/instant?query=';
  const commonUrl = 'https://trackapi.nutritionix.com/v2/natural/nutrients';
  const brandedUrl =
    'https://trackapi.nutritionix.com/v2/search/item?nix_item_id=';

  const [query, setQuery] = useState(null);
  const [food, setFood] = useState(null);
  const [data, setData] = useState(initData);

  const resetForm = () => {
    let el = document.getElementById('search');
    el.value = '';
    el.focus();
    setQuery(null);
    setFood(null);
    setData(initData);
    searchTabLeft();
  };

  const getCommonItem = e => {
    async function fetchCommonData() {
      const response = await fetch(commonUrl, {
        method: 'POST',
        headers: {
          ...headers,
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          query: e.target.id,
        }),
      });
      const result = await response.json();
      setFood(result.foods[0]);
    }

    e.preventDefault();
    fetchCommonData();
  };

  const getBrandedItem = e => {
    async function fetchBrandedData() {
      const response = await fetch(brandedUrl + e.target.id, { headers });
      const result = await response.json();
      setFood(result.foods[0]);
    }

    e.preventDefault();
    fetchBrandedData();
  };

  useEffect(() => {
    const eventListenter = e => {
      let el = document.getElementById('hidden-sidenav-close-button');
      if (el && !query && e.keyCode === 27) {
        // ESCAPE
        el.click();
      }
    };
    document.addEventListener('keydown', eventListenter);
    return () => {
      document.removeEventListener('keydown', eventListenter);
    };
  });

  // clears the search form when user navigates anywhere other than home
  useEffect(() => {
    if (query && backArrow === true) resetForm();
  }, [backArrow]); // eslint-disable-line

  useEffect(() => {
    async function fetchData() {
      fetchBegin();
      const response = await fetch(instantUrl + query, { headers });
      const result = await response.json();
      fetchEnd();
      let common = result.common ? result.common.slice(0, MAX_COMMON) : null;
      let branded = result.branded
        ? result.branded.slice(0, MAX_BRANDED)
        : null;
      setData({
        common,
        branded,
      });
    }
    if (query && query !== '') fetchData();
    if (query === '') setData(initData); // when deleting search text to empty input
  }, [query]); // eslint-disable-line

  return (
    <div id="side-form" className="sidenav side-form">
      <nav>
        <form autoComplete="off" onSubmit={e => e.preventDefault()}>
          <div>
            <input
              className="browser-default"
              id="search"
              type="text"
              placeholder={
                query
                  ? 'Enter new search (last search below)'
                  : 'Enter food or drink name'
              }
              onChange={e => {
                let val = e.target.value;
                clearTimeout(timeout);
                timeout = setTimeout(() => setQuery(val), DELAY);
              }}
            />
            {query && query !== '' ? (
              <i className="material-icons" id="search-x" onClick={resetForm}>
                close
              </i>
            ) : null}
          </div>
        </form>
      </nav>
      <div>
        {data.common.length + data.branded.length > 0 ? (
          searchTab === 'left' ? (
            <>
              <TabButtons active="left" />
              <div>
                <Autocomplete
                  data={data}
                  getItem={getCommonItem}
                  resetForm={resetForm}
                  group="common"
                  foodId="food_name"
                  foodName="food_name"
                />
              </div>
            </>
          ) : searchTab === 'right' ? (
            <>
              <TabButtons active="right" />
              <div>
                <Autocomplete
                  data={data}
                  getItem={getBrandedItem}
                  resetForm={resetForm}
                  group="branded"
                  foodId="nix_item_id"
                  foodName="brand_name_item_name"
                />
              </div>
            </>
          ) : null
        ) : query && fetchingData === false ? (
          <div>
            <div className="search-subheader">
              {' '}
              No results for this search... keep typing!{' '}
            </div>
            {isAuthenticated ? (
              <div className="callout-container">
                <p className="mini-header">
                  <b>Can't find the right label?</b>
                </p>
                <br />
                <p className="callout">
                  Create & search <b>custom</b> labels{' '}
                  <Link to="/custom" className="sidenav-close">
                    <b>here!</b>
                  </Link>
                </p>
              </div>
            ) : (
              <div className="callout-container">
                <p>
                  <b>Can't find the right label?</b>
                </p>
                <br />
                <p className="callout">
                  <Link to="/login" className="sidenav-close">
                    <b>Log in</b>
                  </Link>{' '}
                  to create <b>custom</b> labels
                </p>
              </div>
            )}
          </div>
        ) : fetchingData ? (
          <div>
            <div className="search-subheader">Searching...</div>
            {isAuthenticated ? (
              <div className="callout-container">
                <p className="mini-header">
                  <b>Can't find the right label?</b>
                </p>
                <br />
                <p className="callout">
                  Create & search <b>custom</b> labels{' '}
                  <Link to="/custom" className="sidenav-close">
                    <b>here!</b>
                  </Link>
                </p>
              </div>
            ) : (
              <div className="callout-container">
                <p className="mini-header">
                  <b>Can't find the right label?</b>
                </p>
                <br />
                <p className="callout">
                  <Link to="/login" className="sidenav-close">
                    <b>Log in</b>
                  </Link>{' '}
                  to create <b>custom</b> labels
                </p>
              </div>
            )}
          </div>
        ) : (
          <div>
            <p className="search-subheader">
              {' '}
              Search from over 500,000 nutrition labels!{' '}
            </p>
            {isAuthenticated ? (
              <div className="callout-container">
                <p className="mini-header">
                  <b>Not looking for a common label?</b>
                </p>
                <br />
                <p className="callout">
                  Create & search <b>custom</b> labels{' '}
                  <Link to="/custom" className="sidenav-close">
                    <b>here!</b>
                  </Link>
                </p>
                <br />
                <p className="mini-header">OR</p>
                <br />
                <p className="callout">
                  Add a meal <b>missing</b> nutrition data{' '}
                  <Link to="/simple" className="sidenav-close">
                    <b>here!</b>
                  </Link>
                </p>
              </div>
            ) : (
              <div className="callout-container">
                <p className="callout">
                  <Link to="/login" className="sidenav-close">
                    <b>Log in</b>
                  </Link>{' '}
                  to create <b>custom</b> labels
                </p>
              </div>
            )}
          </div>
        )}
        <div>
          <NutritionLabel food={food} />
        </div>
      </div>
      <div className="center nutritionix-attrib">
        Powered by nutrition<span style={{ color: 'green' }}>ix</span>
      </div>
      <button
        className="sidenav-close"
        id="hidden-sidenav-close-button"></button>
    </div>
  );
};

const mapState = state => ({
  searchTab: state.nav.searchTab,
  backArrow: state.nav.backArrow,
  fetchingData: state.nav.fetchingData,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatch = dispatch => ({
  searchTabLeft: () => dispatch({ type: 'SEARCH_TAB_LEFT' }),
  fetchBegin: () => dispatch({ type: 'FETCH_BEGIN' }),
  fetchEnd: () => dispatch({ type: 'FETCH_END' }),
});

export default connect(mapState, mapDispatch)(SideForm);

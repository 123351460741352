import { db } from '../config/fbConfig';

export const saveCustomDv = customDv => {
  return (dispatch, getState) => {
    const userId = getState().auth.user.uid;

    db.collection('users')
      .doc(userId)
      .update(customDv)
      .then(() => {
        dispatch({
          type: 'SAVE_CUSTOM_DV',
          payload: customDv,
        });
      })
      .catch(error => {
        dispatch({
          type: 'SAVE_CUSTOM_DV_ERROR',
          error,
        });
      });
  };
};

export const saveCustomLabel = label => {
  return (dispatch, getState) => {
    const userId = getState().auth.user.uid;
    const event = new Date();
    const data = {
      ...label,
      userId,
      createdAt: event.toJSON(),
    };

    db.collection('customlabels')
      .add(data)
      .then(docRef => {
        dispatch({
          type: 'SAVE_CUSTOM_LABEL',
          data,
          id: docRef.id,
        });
      })
      .catch(error => {
        dispatch({
          type: 'SAVE_CUSTOM_LABEL_ERROR',
          error,
        });
      });
  };
};

export const saveMeal = meal => {
  return (dispatch, getState) => {
    const userId = getState().auth.user.uid;
    const event = new Date();
    const data = {
      ...meal,
      userId,
      createdAt: event.toJSON(),
    };

    db.collection('meals')
      .add(data)
      .then(docRef => {
        dispatch({
          type: 'SAVE_MEAL',
          data,
          id: docRef.id,
        });
      })
      .catch(error => {
        dispatch({
          type: 'SAVE_MEAL_ERROR',
          error,
        });
      });
  };
};

export const deleteMeal = id => {
  return dispatch => {
    db.collection('meals')
      .doc(id)
      .delete()
      .then(() => {
        dispatch({
          type: 'DELETE_MEAL',
          id,
        });
      })
      .catch(error => {
        dispatch({
          type: 'DELETE_MEAL_ERROR',
          error,
        });
      });
  };
};

export const deleteCustomLabel = id => {
  return dispatch => {
    db.collection('customlabels')
      .doc(id)
      .delete()
      .then(() => {
        dispatch({
          type: 'DELETE_CUSTOM_LABEL',
          id,
        });
      })
      .catch(error => {
        dispatch({
          type: 'DELETE_CUSTOM_LABEL_ERROR',
          error,
        });
      });
  };
};

export const editMealName = (id, name) => {
  return dispatch => {
    db.collection('meals')
      .doc(id)
      .update({
        food_name: name,
      })
      .then(() => {
        dispatch({
          type: 'UPDATE_MEAL_NAME',
          id,
          name,
        });
      })
      .catch(error => {
        dispatch({
          type: 'UPDATE_MEAL_NAME_ERROR',
          error,
        });
      });
  };
};

export const editCustomMealName = (id, name) => {
  return dispatch => {
    db.collection('customlabels')
      .doc(id)
      .update({
        food_name: name,
      })
      .then(() => {
        dispatch({
          type: 'UPDATE_CUSTOM_MEAL_NAME',
          id,
          name,
        });
      })
      .catch(error => {
        dispatch({
          type: 'UPDATE_CUSTOM_MEAL_NAME_ERROR',
          error,
        });
      });
  };
};

export const editServing = (id, label) => {
  return dispatch => {
    db.collection('meals')
      .doc(id)
      .update(label)
      .then(() => {
        dispatch({
          type: 'UPDATE_SERVING',
          id,
          label,
        });
      })
      .catch(error => {
        dispatch({
          type: 'UPDATE_SERVING_ERROR',
          error,
        });
      });
  };
};

export const editTimestamp = (id, timestamp) => {
  return dispatch => {
    db.collection('meals')
      .doc(id)
      .update({
        createdAt: timestamp,
      })
      .then(() => {
        dispatch({
          type: 'UPDATE_TIMESTAMP',
          id,
          timestamp,
        });
      })
      .catch(error => {
        dispatch({
          type: 'UPDATE_TIMESTAMP_ERROR',
          error,
        });
      });
  };
};

export const saveMergedMeal = meal => {
  return (dispatch, getState) => {
    const userId = getState().auth.user.uid;
    const data = {
      ...meal,
      userId,
    };

    db.collection('meals')
      .add(data)
      .then(docRef => {
        dispatch({
          type: 'SAVE_MEAL',
          data,
          id: docRef.id,
        });
      })
      .catch(error => {
        dispatch({
          type: 'SAVE_MEAL_ERROR',
          error,
        });
      });
  };
};

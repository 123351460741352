import React from 'react';

const ConfirmDelete = ({ toggleModal, confirmDelete }) => {
  return (
    <div className="modal-container">
      <p>Permanently delete? </p>
      <p>You can't undo this action</p>
      <div className="modal-button-container">
        <button className="button-white clear-button" onClick={toggleModal}>
          Cancel
        </button>
        <button
          className="button-white modal-delete"
          id="modal-delete"
          onClick={confirmDelete}>
          Delete
        </button>
      </div>
    </div>
  );
};

export default ConfirmDelete;
